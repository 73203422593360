import { Logger } from '../../utils/Logger'; 
import { gsap } from 'gsap';

import './Header.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[HeaderBlock]';
const TAG_NAME = 'chunkwc-header';

const DESKTOP_MENU_MIN_WIDTH = 1440;	
// ////////////////////////////////////////////////////////////////////

export default class HeaderBlock extends HTMLElement {
	private _button: HTMLElement;
	private _logo: HTMLElement;
	private _menu: HTMLElement;
	private _links: HTMLCollection;	

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		let node = document.createElement('div');
		node.setAttribute("id", "contentstart");
		let target = document.querySelector('.chunkwc__hero');
		if(!target) {
			target = document.querySelector('h1');
		}

		target.after(node);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		Logger.log(`${CLASS_NAME} Component connected`);

		// Bind the component to _el so that we can refer to it
		this._button = this.querySelector('button');
		this._logo = this.querySelector('.chunkwc--header__logo');
		this._menu = this.querySelector('.chunkwc--header__links');
		this._links = this._menu.querySelector('.chunkwc--header__menu').children;

		let onClick = this.onClick.bind(this);

		if (this._button && this._menu) this.addEventListener('click', function (e) {
			onClick(e);	
		});

		this.setGSAPStyles();
		this.onResize();
		// this.removeLanguages();

	}

	setGSAPStyles() {
		gsap.set(this._links, window.innerWidth < DESKTOP_MENU_MIN_WIDTH ? { opacity: 0, x: 10 } : { opacity: 1, x: 0} );
	}

	onResize() {
		window.addEventListener('resize', () => {
			if(!this._logo.classList.contains('is-active')){
				this.setGSAPStyles();
			}
		})
	}

	onClick(e: MouseEvent) {
		// @ts-ignore
		let target = e.target as HTMLElement,
			activeState = this.querySelector('.is-active') == null ? true : false;

		// smooth scroll to anchored sections
		// @ts-ignore
		if(target.href !== undefined) {

			// @ts-ignore
			if(target.href.includes('#')){
				e.preventDefault();

				// @ts-ignore
				let selectedAnchor = document.querySelector(`#${target.href.split("#")[1]}`),
					shortcutMenuHeight = document.querySelector('chunkwc-shortcut-menu')
				? document.querySelector('chunkwc-shortcut-menu').clientHeight
				: 0;
	
				window.scrollTo({
					left: 0,
					top: selectedAnchor ? selectedAnchor.getBoundingClientRect().top + (document.documentElement.scrollTop - shortcutMenuHeight) : 0,
					behavior: 'smooth',
				});
			}
		}

		// mobile
		if(window.innerWidth < DESKTOP_MENU_MIN_WIDTH) {
			if((target.classList.contains('chunkwc--header__logo') && activeState)){

				this.toggleMenu(false);

				return;
			}

			if((target.tagName == 'A')){
				this.toggleMenu(activeState);
			}
	
			if(target.tagName == 'BUTTON'){
				window.scrollTo(0, 0);

				this.toggleMenu(activeState);
			}
		}
	}

	toggleMenu(active: boolean) {
		this._logo.classList.toggle('is-active', active);
		this._button.classList.toggle('is-active', active);
		this._menu.classList.toggle('is-active', active);

		document.querySelector('body').classList.toggle('no-scroll', active);

		// hide these so they are not accessible via tabbing voice over when menu is open
		document.getElementById('content').classList.toggle('hidden', active);
		document.querySelector('.footer').classList.toggle('hidden', active);

		gsap.to(this._menu, { autoAlpha: active ? 1 : 0, pointerEvents: 'auto'});

		if(window.innerWidth < DESKTOP_MENU_MIN_WIDTH) {
			!active ? gsap.to(this._links, { opacity: 0, x: 10 }) : gsap.to(this._links, { stagger: 0.15, opacity: 1, x: 0 });
		}
	}

	removeLanguages(){
		if(document.querySelector('.menu-item-weglot-480-ko')){
			document.querySelector('.menu-item-weglot-480-ko').remove();
		   document.querySelector('.menu-item-weglot-480-zh').remove();
		}
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, HeaderBlock);