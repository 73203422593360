import './AspectRatio.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = 'AspectRatio';
const TAG_NAME = 'chunkwc-aspect-ratio';

// ////////////////////////////////////////////////////////////////////

export default class AspectRatio extends HTMLElement {
	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		const shadow = this.attachShadow({ mode: 'open' });

		if (!shadow) return;

		// Wrap if browser doesn't support `aspect-ratio` natively.
		if (CSS.supports('aspect-ratio', '1/1') === false) {
			shadow.innerHTML = `<style>
			.component-aspect-ratio-inner {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow-y: var(--overflow-y);
			}
			</style>
			<div class="component-aspect-ratio-inner"><slot></slot></div>`;
		} else {
			shadow.innerHTML = `<slot></slot>`;
		}
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, AspectRatio);
