//////////////////////////////////////////////////////////////////////////////////////////////////////

export const LOGGING_ENABLED: boolean = true;
export const TAXI_ENABLED: boolean = true;

export const ASSETS_DIR: string = `/wp-content/plugins/chunk-wordpress-blocks/public/assets`;

let url = new URL(window.location.href);
export const SITE_DOMAIN = `${url.protocol}//${url.hostname}`;

export const enum Bool {
	True = 'true',
	False = 'false',
}

export const enum SwiperClass {
	Pagination = '.swiper-pagination',
	NavigationPrev = '.swiper-button-prev',
	NavigationNext = '.swiper-button-next',
	Scrollbar = '.swiper-scrollbar',
}
