import { Bool } from './constants';

export default class AttributesHelper {
	constructor(private target: HTMLElement) {}

	public getAttribute(attrName: string): null | boolean | string | number {
		const value = this.target.getAttribute(attrName);

		if (value === null) return null;

		const number = parseFloat(value);

		switch (true) {
			case Number.isNaN(number) === false:
				return number;

			// `value` was string "false"
			case value?.toLowerCase() === Bool.False:
				return false;

			// `value` was string "true" or empty string.
			// Assume that adding `scrollbar` attribute means you want a scrollbar.
			case value === '':
			case value?.toLowerCase() === Bool.True:
				return true;

			// `value` was valid string
			default:
				return value;
		}
	}
}
