import './Intro.scss';

import GLightbox from 'glightbox';

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[IntroBlock]';
const TAG_NAME = 'chunkwc-intro';



// ////////////////////////////////////////////////////////////////////

export default class IntroBlock extends HTMLElement {
	private _glightboxContainer: HTMLElement;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		const lightbox = GLightbox({
			closeButton : false
		});

		lightbox.on('open', () => {
			this._glightboxContainer = document.querySelector('.ginner-container');

			this._glightboxContainer.addEventListener('click', () => {
				lightbox.close();
			})
		});
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, IntroBlock);