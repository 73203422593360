import "./Dollhouse.scss";

import Swiper, {SwiperOptions } from "swiper";

import gsap from "gsap";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[DollhouseBlock]";
const TAG_NAME = "chunkwc-dollhouse";

// ////////////////////////////////////////////////////////////////////

export default class DollhouseBlock extends HTMLElement {
  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  imageContainer: HTMLElement;
  svg: SVGElement;
  image: HTMLImageElement;
  button: HTMLButtonElement;
  ui: HTMLDivElement;

  scale: number;

  legendItems: HTMLElement[];
  swiper: Swiper;
  pinsContainer: HTMLElement;
  markerWidth: number;
  markerHeight: number;
  pins: HTMLElement[] = [];

  initialWidth: number;
  initialHeight: number;

  // Default Swiper Options
  private swiperOptions: SwiperOptions = {
    loop: false,
    speed: 750,
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      dragSize: 59,
    },
    breakpoints: {
      1: {
        slidesPerView: 1.25,
        slidesPerGroup: 1,
        direction: "horizontal",
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        direction: "horizontal",
      }
    },
  };

  init() {
    this.initDOMElements();
    this.handleButton();
    this.handlePinClick();
    this.initLegendItems();
    this.initSwiper();
  }

  destroy() {}

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initDOMElements() {
    // Get a handle to all the elemnts we need

    this.imageContainer = this.querySelector(".component-dollhouse__image");
    this.image = this.imageContainer.querySelector("img") as HTMLImageElement;
    this.svg = this.imageContainer.querySelector("svg") as SVGSVGElement;
    this.pinsContainer = this.querySelector(".component-dollhouse__image-pins");
    this.button = this.querySelector(".component-dollhouse__button");
    this.ui = this.querySelector(".component-dollhouse__ui");

    // Wait for the image to load before calculating scale
    if (this.image.complete) {
      DEBUG_VERBOSE && console.log("Image was loaded before listener applied");
      this.setInitialScale();
    } else {
      DEBUG_VERBOSE && console.log("Waiting for image to load");
      this.image.onload = () => {
        DEBUG_VERBOSE && console.log("Image is loaded");
        this.setInitialScale();
      };
    }

    this.legendItems = Array.from(
      this.querySelectorAll("[data-location]")
    ) as HTMLElement[];
  }


  setInitialScale() {
    this.scale = this.image.clientWidth / this.image.naturalWidth;

    DEBUG_VERBOSE &&
      console.log(
        `[Dollhouse] The dollhouse image is ${this.image.naturalWidth}x${this.image.naturalHeight}, scaled to ${this.image.clientWidth}x${this.image.clientHeight}`
      );
    DEBUG_VERBOSE &&
      console.log(`[Dollhouse] Initial image scale is ${this.scale}`);

    this.createPins();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleButton() {
    this.button.addEventListener('click', () => {
      let annotation = this.querySelector('.component-dollhouse__annotation') as HTMLElement;

      // animations
      if(this.ui.classList.contains('active')){
        // annotation
        if(annotation) {
          gsap.to(annotation, {
            opacity: 0,
            duration: 0.5,
          })
        } 
      } else {
        // annotation
        if(annotation) {
          gsap.to(annotation, {
            opacity: 1,
            duration: 0.5,
          })
        }   
      }

      // add active state to ui
      this.ui.classList.toggle('active');
    })
  }
  
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  handleAnnotation(idStr: string){
    let labelContainer = this.querySelector(`[data-location='${idStr}']`).querySelector('.component-dollhouse__location') as HTMLDivElement,
        pins = Array.from(this.querySelectorAll(`.component-dollhouse__pin.location-${idStr}`)) as HTMLElement[],
        annotations = Array.from(this.querySelectorAll('.component-dollhouse__annotation')) as HTMLElement[];
    
    if(annotations) {
      annotations.forEach(el => {
        if(el.dataset.location == idStr) return;

        const tl = gsap.timeline({
          onComplete: () => {
            el.remove();
          },
        });
  
        tl.to(el, { duration: 0.5, opacity: 0 });
      });

    }

    if(!labelContainer) {
      console.log(`[Dollhouse] Label with location id of ${idStr} does not exist, can't create annotation`);

      return;
    }

    if(!pins) { 
      console.log(`[Dollhouse] Pin with location id of ${idStr} does not exist, can't create annotation`);

      return;
    }

    pins.forEach(el => {
      let p  = document.createElement('p') as HTMLParagraphElement,
          label = labelContainer.querySelector('.component-dollhouse__label') as HTMLParagraphElement;
  
      gsap.set(p, 
        { opacity: 0 }
      );
  
      p.className = "component-dollhouse__annotation";
      p.innerText = label.innerText;
      p.dataset.location = idStr;
      
      el.appendChild(p);
  
      gsap.to(p, 
        { duration: 0.5, opacity: 1, ease: 'ease-in-out' }
      );
  
      console.log(`[Dollhouse] Annotation created for Location ${idStr}`);
    });
  }

  handlePinClick() {
    this.querySelector(".component-dollhouse").addEventListener("click", (e) => {
      let target = e.target as HTMLElement;

      if(target.classList.contains('component-dollhouse__pin')) {
        let pin = target,
            id = Number(pin.querySelector("p").innerText);

          if(window.innerWidth < 1366) {
            if (id <= 5) {
              this.swiper.slideTo(0, 300, true);
            } else if(id <= 10) {
              this.swiper.slideTo(1, 300, true);
            } else if(id <= 15) {
              this.swiper.slideTo(2, 300, true);
            } else {
              this.swiper.slideTo(3, 300, true);
            }
            
            return;
          }
              
          let wrapper = this.querySelector('.swiper-wrapper');

          wrapper.scrollTo({
           left: 0,
           top: (id - 1) * 46,
           behavior: 'smooth'  
          });
      }
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createPins() {
    // Get the annotations from the image and re-create them in the DOM layer

    // First, set the SVG container to be the natural size of the image
    this.svg.style.width = this.pinsContainer.style.width =
      this.image.naturalWidth + "px";
    this.svg.style.height = this.pinsContainer.style.height =
      this.image.naturalHeight + "px";

    let markers = Array.from(
      this.svg.querySelectorAll('[class^="location-"]')
    ) as SVGGElement[];

    markers.forEach((marker: SVGElement) => {
      // Get the id of the annotation from the SVG class

      console.log("CREATING MARKER " + marker);

      let id = marker.classList[0].replace("location-", "");

      // Get the position of the marker in the SVG, offseting it by the position of the image on the page
      let markerX =
        marker.getBoundingClientRect().left -
        this.image.getBoundingClientRect().left;
      let markerY =
        marker.getBoundingClientRect().top -
        this.image.getBoundingClientRect().top;

      // Set the width and height to be identical to prevent any rounding errors
      this.markerWidth = this.markerHeight =
        marker.getBoundingClientRect().width;

      // Create the pin
      this.createPin(markerX, markerY, id);
    });

    // Scale the pins to now match the image
    this.scalePins();

    // Add a listener so that we resize the pins on window resizing
    window.addEventListener("resize", () => {
      this.scalePins();
    });
  }

  createPin(x: number, y: number, id: string) {
    let pin = document.createElement("button");
    pin.dataset.aos = "fade";

    let delay = (100 * parseInt(id)).toString();
    pin.dataset.aosDelay = delay;
    pin.classList.add("component-dollhouse__pin");
    pin.classList.add("location-" + id);
    pin.style.top = y + "px";
    pin.style.left = x + "px";
    pin.ariaLabel = `Select location ${id}`;

    // Set the pin to be the same width and height as the SVG marker
    // pin.style.width = `${this.markerWidth}px`;
    // pin.style.height = `${this.markerHeight}px`;

    let label = document.createElement("p");
    label.innerHTML = id;

    pin.appendChild(label);

    pin.addEventListener("click", () => {
      this.setActivePin(id);
      this.handleAnnotation(id);
    });

    this.pins.push(pin);
    this.pinsContainer.appendChild(pin);
  }

  scalePins() {
    let scale = this.image.clientWidth / this.image.naturalWidth;
    let scaledDiameter = this.pins[0].clientWidth * scale;
    this.pinsContainer.style.transform = `scale(${scale})`;

    let targetDiameter: number, pinScale: number;
    if (window.innerWidth < 1366) {
      targetDiameter = 16;
      pinScale = targetDiameter / scaledDiameter;
    } else if (window.innerWidth >= 1366) {
      targetDiameter = 18;
      pinScale = targetDiameter / scaledDiameter;
    }

    if (targetDiameter) {
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Pins scaled up by the container are currently ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Need to scale pins by a factor of ${pinScale} to maintain a size of ${targetDiameter}`
        );

      this.pins.forEach((pin) => {
        pin.style.transform = `scale(${pinScale})`;
      });

      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Pins are now ${scaledDiameter} scaled to ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
    } else {
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] No scaling needed. Pins are currently ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
    }

    this.pinsContainer.style.transform = `scale(${scale})`;
  }

  setActivePin(idStr: string) {
    this.legendItems.forEach((legendItem) => {
      const { location } = legendItem.dataset;
      legendItem.classList.toggle("active", location === idStr);
    });

    if(window.innerWidth < 1366) {
      // Scroll the legend to the right place
      let slide = Math.floor(
        (parseInt(idStr) - 1) / parseInt(this.dataset.legendRows)
      );

      this.swiper.slideTo(slide);
    }

    this.pins.forEach((pin) => {
      const className = `location-${idStr}`;
      pin.classList.toggle("active", pin.classList.contains(className));
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initLegendItems() {
    this.legendItems.forEach((legendItem) => {
      legendItem.addEventListener("click", () => {
        const { location: idStr } = legendItem.dataset;
        this.setActivePin(idStr);
        this.handleAnnotation(idStr);
      });
    });
  }

  initSwiper() {
    if(window.innerWidth < 1366){
      const swiperEl = this.querySelector(".swiper") as HTMLElement;
      this.swiper = new Swiper(swiperEl, this.swiperOptions);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.destroy();
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}
}

customElements.define(TAG_NAME, DollhouseBlock);
