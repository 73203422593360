import './StaggeredImages.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[StaggeredImagesBlock]';
const TAG_NAME = 'chunkwc-staggered-images';

// ////////////////////////////////////////////////////////////////////

export default class StaggeredImagesBlock extends HTMLElement {
	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, StaggeredImagesBlock);