import { LOGGING_ENABLED } from "../config/constants";

/* eslint-disable */
// eslint disabled as we dont want it moaning about all these console calls.

declare global {
  interface Console {
    memory: any;
    assert(condition?: boolean, ...data: any[]): void;
    clear(): void;
    count(label?: string): void;
    countReset(label?: string): void;
    debug(...data: any[]): void;
    dir(item?: any, options?: any): void;
    dirxml(...data: any[]): void;
    error(...data: any[]): void;
    exception(message?: string, ...optionalParams: any[]): void;
    group(...data: any[]): void;
    groupCollapsed(...data: any[]): void;
    groupEnd(): void;
    info(...data: any[]): void;
    log(...data: any[]): void;
    profile(label?: string): void;
    profileEnd(label?: string): void;
    table(tabularData?: any, properties?: string[]): void;
    time(label?: string): void;
    timeEnd(label?: string): void;
    timeLog(label?: string, ...data: any[]): void;
    timeStamp(label?: string): void;
    trace(...data: any[]): void;
    warn(...data: any[]): void;
  }
}

const nop: any = () => {};

export class Logger {
  private static _enabled = LOGGING_ENABLED;

  static log(...args: any[]): void {
    let time = Math.round(performance.now());
    let stackOutput = getStackTraceInfo(2, false).join(" <= ");
    var css = "color: #777777; font-size: 8px;";
    let format = "";
    let containsObject = false;
    for (var i = 0; i < args.length; i++) {
      containsObject ||= typeof args[i] == "object";
    }
    let token = containsObject ? "%o" : "%s";
    for (var i = 0; i < args.length; i++) {
      format = `${token} ${format}`;
    }
    format = `${format} %c\n%s`;
    args.unshift(format);
    args.push(css, `[${time}] ${stackOutput}`);
    if (this.enabled) console.log.apply(console, args);
  }

  static get enabled() {
    return this._enabled;
  }

  static set enabled(state: boolean) {
    this._enabled = state;
  }

  static get assert() {
    return this._enabled ? console.assert.bind(console) : nop;
  }

  static get clear() {
    return this._enabled ? console.clear.bind(console) : nop;
  }

  static get count() {
    return this._enabled ? console.count.bind(console) : nop;
  }

  static get countReset() {
    return this._enabled ? console.countReset.bind(console) : nop;
  }

  static get debug() {
    return this._enabled ? console.debug.bind(console) : nop;
  }
  static get dir() {
    return this._enabled ? console.dir.bind(console) : nop;
  }

  static get dirxml() {
    return this._enabled ? console.dirxml.bind(console) : nop;
  }

  static get error() {
    return this._enabled ? console.error.bind(console) : nop;
  }

  // --------------------------------------------------------------------
  // Grouping
  // Example: https://twitter.com/wesbos/status/1345075611740622848

  static get group() {
    return this._enabled ? console.group.bind(console) : nop;
  }

  static get groupCollapsed() {
    return this._enabled ? console.groupCollapsed.bind(console) : nop;
  }

  static get groupEnd() {
    return this._enabled ? console.groupEnd.bind(console) : nop;
  }

  static get info() {
    return this._enabled ? console.info.bind(console) : nop;
  }

  /*get log() {
		return this._enabled ? console.log.bind(console) : nop;
	}*/

  // Non-standard but compatible https://developer.mozilla.org/en-US/docs/Web/API/Console/profile
  static get profile() {
    return this.enabled ? console.profile.bind(console) : nop;
  }

  static get profileEnd() {
    return this.enabled ? console.profileEnd.bind(console) : nop;
  }

  static get table() {
    return this._enabled ? console.table.bind(console) : nop;
  }

  static get time() {
    return this._enabled ? console.time.bind(console) : nop;
  }

  static get timeEnd() {
    return this._enabled ? console.timeEnd.bind(console) : nop;
  }

  static get timeLog() {
    return this._enabled ? console.timeLog.bind(console) : nop;
  }

  // Non-standard but compatible https://developer.mozilla.org/en-US/docs/Web/API/Console/timeStamp
  static get timeStamp() {
    return this._enabled ? console.timeStamp.bind(console) : nop;
  }

  static get trace() {
    return this._enabled ? console.trace.bind(console) : nop;
  }

  static get warn() {
    return this._enabled ? console.warn.bind(console) : nop;
  }
}

// const logger = new Logger();
// export default logger;

function getStackTraceInfo(
  truncateStart: number,
  detailedFirstEntry: boolean
): string[] {
  let stack = Error().stack;
  if (!stack) {
    return [];
  }
  let entries: string[] = [];
  const regex = new RegExp(/ *at ([^(]+) \((.+?):(\d+):(\d+)\)/g);
  let stackLines = stack.split("\n");
  for (let i = 0; i < stackLines.length; i++) {
    let match: any;
    let parts: string[] = [];
    while ((match = regex.exec(stackLines[i])) !== null) {
      parts = parts.concat(match);
    }
    if (parts && parts.length) {
      const functionName = parts[1];
      const fileUrl = parts[2];
      const lineNumber = parts[3];
      if (fileUrl) {
        let fileRegex = new RegExp(/\/([^\/?#]+)\?/g);
        let filenameMatch = fileRegex.exec(fileUrl);
        const fileName = filenameMatch?.length ? filenameMatch[1] : fileUrl;
        if (entries.length == truncateStart && detailedFirstEntry) {
          entries.push(
            functionName + " (" + fileName + " L" + lineNumber + ")"
          );
        } else {
          entries.push(functionName);
        }
      }
    } else {
      //console.log("NO MATCH:", stackLines[i]);
    }
  }
  entries.splice(0, truncateStart);
  return entries;
}
