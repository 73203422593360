import "./FloorPlate-v2.scss";
import { gsap } from 'gsap';

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[FloorPlateBlockV2]";
const TAG_NAME = "chunkwc-floor-plate-v2";

const ZOOMABLE_MAX_WIDTH: number = 768;
const CLICKABLE_MAX_WIDTH: number = 1200;
const TOUCHES_TO_DRAG: number = 1;  

export default class FloorPlateBlockV2 extends HTMLElement {
  svg: SVGSVGElement;
  svgEl: HTMLElement;
  tabs: HTMLElement[];
  zoomEl: SVGSVGElement;
  modal: HTMLElement;
  modalContent: HTMLElement;
  modalCloseBtn: HTMLElement;
  body: HTMLElement;

  allowModals: boolean = false;

  floorSelectEl: HTMLSelectElement; 
  modalEl: HTMLElement;
  popupEl: HTMLElement;
  residences: HTMLElement[];

  residenceEyebrow: HTMLElement;
  residenceDisclaimer: HTMLElement;

  residenceTypeEl: HTMLElement; 
  residenceTitleEl: HTMLElement;
  residenceDescriptionEl: HTMLElement;

  residenceDimensions: HTMLElement;
  residenceInteriorLabelEl: HTMLElement;
  residenceInteriorValueEl: HTMLElement;

  residenceExterior: HTMLElement;
  residenceExteriorLabelEl: HTMLElement;
  residenceExteriorValueEl: HTMLElement;

  residenceFloorplanWrapper: HTMLElement;
  residenceModalKey: HTMLImageElement;

  currentResidence: string;

  touchStartTime: number;

  debouncedResize: any;

  languageCode: string;
  currentTab: number = 0;

  frameID: number;
  lastX: number = 0;
  lastY: number = 0;

  isDragging: boolean = false;
  hasRequiredTouches: boolean = false;
  dragX: number = 0;
  dragY: number = 0;

  isZoomable: boolean = window.innerWidth < ZOOMABLE_MAX_WIDTH;
  handleZoom: any;
  handleDrag: any;
  handleDragPointerUp: any;
  handleDragPointerDown: any; 
  handleResize: any;

  requiresClick: boolean = window.innerWidth < CLICKABLE_MAX_WIDTH || this.allowModals;  
  handleClick: any;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
    DEBUG_VERBOSE && console.log(CLASS_NAME, "clickable? " + this.requiresClick);

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.destroy();
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  init() {

    // Get the language details (this is written into the component via Weglot)
    // this.dataset.language = 'jp';
    this.languageCode = this.dataset.language;

    this.allowModals = this.dataset.version === 'plans';

    this.requiresClick = window.innerWidth < CLICKABLE_MAX_WIDTH || this.allowModals;  
  

    /* Get handles to all the DOM elements we need */
    this.initDOMElements();

    /* Add listeners for the tabs and the select */
    this.addUIListeners();

    this.addSVGListeners();

    this.addModalListeners();


  }

  destroy() {
    DEBUG_VERBOSE && console.log("destroy");
    this.removeAllListeners();
    this.removeResizeListener();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initDOMElements() {
    this.body = document.querySelector("body") as HTMLElement;

    this.svgEl = this.querySelector(".chunkwc__floor-plate-v2__svg__desktop-image") as HTMLElement;
    this.svg = this.svgEl.querySelector("svg") as SVGSVGElement;
    this.tabs = Array.from(this.querySelectorAll('[data-tab]')) as HTMLElement[];
    this.floorSelectEl = this.querySelector('#floor_select') as HTMLSelectElement;

    this.zoomEl = this.querySelector('.chunkwc__floor-plate-v2__zoom') as SVGSVGElement;

    // Grab handles to the two popup types, and delete the unrequired one
    const modal = this.querySelector('.chunkwc__floor-plate-v2__modal') as HTMLElement;
    const popup = this.querySelector('.chunkwc__floor-plate-v2__popup') as HTMLElement;
    this.modalEl = this.allowModals ? modal : popup;

    if (this.allowModals) {
      popup.remove();
    } else {
      modal.remove();
    }

    this.modalContent = this.modalEl.querySelector('.chunkwc__floor-plate-v2__content') as HTMLElement;
    this.modalCloseBtn = this.modalEl.querySelector('.chunkwc__floor-plate-v2__cross') as HTMLElement; 

    this.residences = Array.from(document.querySelectorAll('.chunkwc__floor-plate-v2__svg .residence'));

    this.residenceEyebrow = this.modalEl.querySelector('.chunkwc__floor-plate-v2__eyebrow') as HTMLElement;
    this.residenceDisclaimer = this.modalEl.querySelector('.chunkwc__floor-plate-v2__disclaimer') as HTMLElement;

    if (!this.allowModals) {
      this.residenceTypeEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__type') as HTMLElement;
    }

    this.residenceTitleEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__title') as HTMLElement;
    this.residenceDescriptionEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__description') as HTMLElement;

    this.residenceDimensions = this.modalEl.querySelector('.chunkwc__floor-plate-v2__dimensions') as HTMLElement;
    this.residenceInteriorLabelEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__interior_label') as HTMLElement;
    this.residenceInteriorValueEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__interior_value') as HTMLElement;

    this.residenceExterior = this.modalEl.querySelector('.chunkwc__floor-plate-v2__exterior') as HTMLElement;
    this.residenceExteriorLabelEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__exterior_label') as HTMLElement;
    this.residenceExteriorValueEl = this.modalEl.querySelector('.chunkwc__floor-plate-v2__exterior_value') as HTMLElement;

    this.residenceFloorplanWrapper = this.modalEl.querySelector('.floorplan_wrapper') as HTMLImageElement;

    this.residenceModalKey = this.modalEl.querySelector('.chunkwc__floor-plate-v2__modal-key') as HTMLImageElement;
  }

  addSVGListeners() {
    this.addResidenceListeners();
    this.addDragListeners();
    this.addZoomListeners();
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	UI
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  addUIListeners() {
    this.addTabListeners();
    this.addSelectListeners();
    this.addLegendListeners();
    this.addResizeListener();
  }

  addTabListeners() {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', this.onTabClick.bind(this), false);
    });
  }

  addSelectListeners() {
    this.floorSelectEl.addEventListener('change', this.onSelectChange.bind(this));
  }

  async onSelectChange(event:any) {

    this.hideModalInfo();
    await this.updateSVG(event.target.value);

    this.setCurrentTab(event.target.selectedIndex);
  }

  async setCurrentTab(tab: number | string) {

    this.currentTab = typeof tab === 'number' ? tab : parseInt(tab);
    this.tabs.forEach((tab: any, index: number) => {
      tab.classList.toggle('is-active', index === this.currentTab); 
    })

    this.floorSelectEl.selectedIndex = this.currentTab; 

  }

  onTabClick = async (event: any) => {
    const target = event.target as HTMLElement;
    await this.updateSVG(target.dataset.imageDesktop);  
    await this.setCurrentTab(parseInt(target.dataset.tab));
    target.classList.add('is-active');
  };

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	RESIDENCES
	------------------------------------------------------------------------------------------------------------------------------------------
	*/


  addResidenceListeners() {

    DEBUG_VERBOSE && console.log("[Residence] Being asked to add residence listeners");
    DEBUG_VERBOSE && console.log("[Residence] Should add click listeners? ", this.requiresClick);

    this.residences = Array.from(document.querySelectorAll('.chunkwc__floor-plate-v2__svg .residence'));  

    this.residences.forEach((residence, index) => {      

      if (this.requiresClick) {
        DEBUG_VERBOSE && console.log("[Residence] Adding click listener for residence ", index);  
        residence.addEventListener('click', this.onResidenceClick, false);  
      }

      DEBUG_VERBOSE && console.log("[Residence] Adding enter and leave listeners for residence ", index);  
      residence.addEventListener('mouseenter', this.onResidenceMouseEnter, false);
      residence.addEventListener('mouseleave', this.onResidenceMouseLeave, false);
    });

  }

  onResidenceMouseEnter = (event: any) => { 
    const target = event.target as HTMLElement;
    this.highlightResidence(target);
    if (!this.requiresClick) {  
      this.fetchResidenceData(target.id); 
    }
  };

  onResidenceMouseLeave = (event: any) => { 
      const target = event.target as HTMLElement;
      this.unhighlightResidence(target);
      if (!this.requiresClick) {
        this.hideModalInfo();  
      }
  }

  onResidenceClick = async (event: any) => {  

    DEBUG_VERBOSE && console.log("[Debug] 01 - onResidenceClick");
    this.isDragging = false;
    const target = event.target as HTMLElement;
    DEBUG_VERBOSE && console.log("[Debug] 02 - Fetching residence data");
    this.fetchResidenceData(target.id); 
  }

  highlightResidence(residenceEl: Element) {
    residenceEl.classList.toggle('highlighted', true);
  }

  unhighlightResidence(residenceEl: HTMLElement) {
    residenceEl.classList.toggle('highlighted', false);
  }

  fetchResidenceData = async (mapID: string) => { 

    DEBUG_VERBOSE && console.log("[Debug] 03 - Fetching residence data");

    DEBUG_VERBOSE && console.log("[Residence] Fetching residence data for residence ", mapID);

    this.currentResidence = mapID;

    DEBUG_VERBOSE && console.log("[Debug] 04 - About to show modal");

    this.showModal().then(async () => {

      DEBUG_VERBOSE && console.log("[Debug] 09 - Modal shown. Fetching data");

      const LANG_PREFIX = this.languageCode !== 'en' ? `/${this.languageCode}` : '';  
      const response = await fetch(
        `${LANG_PREFIX}/wp-json/chunk-wp-blocks/v1/residences/${mapID}`
        // `/wp-json/chunk-wp-blocks/v1/residences/${mapID}`
      );

      DEBUG_VERBOSE && console.log("[Debug] 10 - Got fetch response");

      if (response.ok) {

        DEBUG_VERBOSE && console.log("[Debug] 11 - Response is good");

        const json = await response.json();
        if (json) {

          DEBUG_VERBOSE && console.log("[Debug] 12 - JSON parsed");
          if (this.currentResidence == mapID) {          
            DEBUG_VERBOSE && console.log("[Debug] 13 - Populating modal");
              this.populateModal(json);            
          } else {
            DEBUG_VERBOSE && console.log("[Debug] 13 - Different residence ID. Loaded: " + mapID + " current " + this.currentResidence);
          }
        }
      }else{

        DEBUG_VERBOSE && console.log("[Debug] 11 - Response is bad");
        const errorInfo:any = {
          description: "Failed to load data for this residence. Please try again.",
          name: "Error"
        };  
  
        this.populateModal(errorInfo);
        
        console.error(`Failed to retrieve residence data for residence '${mapID}': `, response.status, response.statusText); 
  
      }

    });


  };

  removeResidenceListeners() {
    DEBUG_VERBOSE && console.log("[Residence] Removing residence listeners");

    // Remove listeners from each residence hover
    this.residences.forEach((residence) => {      
      residence.removeEventListener('click', this.onResidenceClick);  
      residence.removeEventListener('mouseenter', this.onResidenceMouseEnter);
      residence.removeEventListener('mouseleave', this.onResidenceMouseLeave);
    });

  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	DRAG
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  addDragListeners() {

    if (this.isZoomable) {

      DEBUG_VERBOSE && console.log("[Drag] Adding drag listeners");

      this.handleDrag = this.onDrag.bind(this);
      this.handleDragPointerDown = this.onDragPointerDown.bind(this);
      this.handleDragPointerUp = this.onDragPointerUp.bind(this);

      this.svgEl.addEventListener('touchstart', this.handleDragPointerDown, {passive: false});
      this.svgEl.addEventListener('mousedown', this.handleDragPointerDown);
      this.svgEl.addEventListener('touchend', this.handleDragPointerUp);
      this.svgEl.addEventListener('mouseup', this.handleDragPointerUp);
      this.svgEl.addEventListener('mouseleave', this.handleDragPointerUp);

    } else {
      DEBUG_VERBOSE && console.log("[Drag] Not adding drag listeners");
    }

  }

  onDragPointerDown(event: MouseEvent | TouchEvent) {  

    if (event instanceof TouchEvent) {
      this.hasRequiredTouches = event.touches.length === TOUCHES_TO_DRAG;
      this.lastX = event.touches[0].clientX;  
      this.lastY = event.touches[0].clientY;
    }else {
      this.lastX = event.clientX;
      this.lastY = event.clientY;  
    }
    
    this.svg.style.cursor = 'grabbing';
    this.isDragging = true;
    document.addEventListener('touchmove', this.handleDrag, {passive: false});
    document.addEventListener('mousemove', this.handleDrag);
  }

  onDragPointerUp(event: MouseEvent | TouchEvent) {
    this.svg.style.cursor = 'grab';
    this.isDragging = false;
    if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent && event.touches.length < TOUCHES_TO_DRAG) {  
      this.hasRequiredTouches = false;
    }

    document.removeEventListener('touchmove', this.handleDrag);
    document.removeEventListener('mousemove', this.handleDrag);
  }

  onDrag(event: MouseEvent | TouchEvent) {

    if (!this.isDragging) {
      return;
    }

    const eventX = typeof TouchEvent !== 'undefined' && event instanceof TouchEvent ? event.touches[0].clientX : event.clientX;
    const eventY = typeof TouchEvent !== 'undefined' && event instanceof TouchEvent ? event.touches[0].clientY : event.clientY;

    const deltaX = this.lastX - eventX;
    const deltaY = this.lastY - eventY;

    if (event instanceof MouseEvent || this.hasRequiredTouches) {

      if (this.hasRequiredTouches) event.preventDefault();

      this.lastX = eventX;
      this.lastY = eventY;
      this.dragX -= deltaX;
      this.dragY -= deltaY;
  
      cancelAnimationFrame(this.frameID);
      this.frameID = requestAnimationFrame(() => {
        this.svgEl.style.transform = `translate3d(${this.dragX}px, ${this.dragY}px, 0)`;
      });  
    }

  }

  resetDrag() {
    this.svgEl.style.transform = `translate3d(0,0,0)`;
    this.lastX = this.dragX = 0;
    this.lastY = this.dragY = 0;
  }

  removeDragListeners() {
    DEBUG_VERBOSE && console.log("[Drag] Removing drag listeners"); 

    this.svgEl.removeEventListener('touchstart', this.handleDragPointerDown);
    this.svgEl.removeEventListener('mousedown', this.handleDragPointerDown);
    this.svgEl.removeEventListener('touchend', this.handleDragPointerUp);
    this.svgEl.removeEventListener('mouseup', this.handleDragPointerUp);
    this.svgEl.removeEventListener('mouseleave', this.handleDragPointerUp);

    this.svgEl.removeEventListener('touchmove', this.handleDrag); 
    this.svgEl.removeEventListener('mousemove', this.handleDrag); 
    
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	LEGEND
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  addLegendListeners() {
    const keys = Array.from(this.querySelectorAll('.chunkwc__floor-plate-v2__key li'));
    keys.forEach((keyItem) => {

      keyItem.addEventListener('mouseenter', this.onLegendMouseEnter.bind(this));
      keyItem.addEventListener('mouseleave', this.onLegendMouseLeave.bind(this));

    })  
  }

  onLegendMouseEnter(event:any) {
    this.residences.forEach((residence) => {
      if (residence.classList.contains(event.target.id)) {  
        this.highlightResidence(residence);
      }
    });
  }

  onLegendMouseLeave(event:any) {
    this.residences.forEach((residence) => {
      this.unhighlightResidence(residence);
    });
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	ZOOM
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  addZoomListeners() {
    if (this.isZoomable) {
      DEBUG_VERBOSE && console.log("[Zoom] Adding zoom listeners");
      this.handleZoom = this.onZoom.bind(this);
      this.svgEl.addEventListener('dblclick', this.handleZoom);
      this.zoomEl.addEventListener('click', this.handleZoom);
    }
  }

  onZoom() {
    this.svg.classList.toggle('zoomed');
    this.zoomEl.classList.toggle('zoomed');
  }

  resetZoom() {
    this.svg.classList.toggle('zoomed', false);
    this.zoomEl.classList.toggle('zoomed', false); 
  }

  removeZoomListeners() {
    DEBUG_VERBOSE && console.log("Removing zoom listeners");
    this.svgEl.removeEventListener('dblclick', this.handleZoom);
    this.zoomEl.removeEventListener('click', this.handleZoom);
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	MODALS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  handleFloorplansImageScaling() {
    // This is a constant to track the size of the total image at our base scale
    // It is more than likely 1400px

    let BASE_IMAGE_SIZE = 1308;

    // This needs to match the breakpoint at which the images stack, so that the calculations
    // are adjusted at the same time

    let STACK_BREAKPOINT = 900;

    let IMAGE_SIZE;

    // Get handles to all our elements

    const wrapper = document.querySelector(".floorplan_wrapper");
    const firstImage = document.querySelector(".floorplan:first-of-type > img");
    const secondImage = document.querySelector(".floorplan.second > img");

    // onyl run if first image is detected
    if (firstImage) {
      DEBUG_VERBOSE && console.log("[Debug] 20 First image exists, so applying scaling");
      // If right is null, then we only have one image
      // const HAS_TWO_IMAGES = secondImage;
  
      // Define a function to resize the images
      const resizeImages = () => {

      IMAGE_SIZE = BASE_IMAGE_SIZE;

      // IMAGE_SIZE = window.innerWidth <= STACK_BREAKPOINT
      //   ? BASE_IMAGE_SIZE / 2
      //   : BASE_IMAGE_SIZE;

      // if (window.innerWidth < IMAGE_SIZE / 2) {
      //   IMAGE_SIZE = IMAGE_SIZE / 2;
      // }

      const STACKED = window.innerWidth < STACK_BREAKPOINT;

      // Here we get the first image styles and grab it's left and right margins
      // so that we account for them in our scaling. This allows them to change at
      // breakpoints, and the scaling to still hang together
      // Oh, and I just log out the actual dimension of the image file for good
      // measure just to help with some debug

      DEBUG_VERBOSE && console.log("[Debug] 21 Setting first plate margin");

      let firstPlateMargin = 0;
      console.log(`First image is natively ${firstImage.naturalWidth}`);
      const firstPlateStyle = window.getComputedStyle(firstImage);
      firstPlateMargin =
        parseFloat(firstPlateStyle.marginLeft) +
        parseFloat(firstPlateStyle.marginRight);
      // And now we do the same for the second image
          
      let secondPlateMargin = 0;

      if (secondImage) {
        DEBUG_VERBOSE && console.log("[Debug] 22 Setting second plate margin");
        console.log(`Second image is natively ${secondImage.naturalWidth}`);
        const secondPlateStyle = window.getComputedStyle(secondImage);
        secondPlateMargin =
          parseFloat(secondPlateStyle.marginLeft) +
          parseFloat(secondPlateStyle.marginRight);
      } else {
        DEBUG_VERBOSE && console.log("[Debug] 22 No second image exists, so not setting margin");
      }
  
        // Add them together so that we know the total horizontal widt
        // lost to the image margins
  
        const imageMargins = firstPlateMargin + secondPlateMargin;
  
        // Now do the same thing, but this time for the paddding of the
        // wrapper element.
  
        const wrapperStyle = window.getComputedStyle(wrapper);
        const wrapperPadding =
          parseFloat(wrapperStyle.paddingLeft) +
          parseFloat(wrapperStyle.paddingRight);
  
        // Note that if the wrapper is usign a margin
        // instead of padding, then we can just use the value that comes
        // from wrapper.getBoundingClientRect() - imageMargins since getBoundingClientRect()
        // doesn't include the margin in its return value
  
        const wrapperSize =
          wrapper.getBoundingClientRect().width - wrapperPadding - imageMargins;
  
        // Log it out for good measure
  
        DEBUG_VERBOSE && console.log(`Wrapper is ${wrapperSize} (based on a ${IMAGE_SIZE}px image)`);
  
        // Now we calculate a scale factor by taking the wrapper size as a percentage
        // of the original image width. There is a small clamp here which stops the
        // scale factor becoming more than 1, as if the window is bigger than our image
        // size we don't want to scale up
  
        const scaleFactor =
          wrapperSize / IMAGE_SIZE > 1 ? 1 : wrapperSize / IMAGE_SIZE;
  
        // You guessed it, log it out for good measure
  
        DEBUG_VERBOSE && console.log(`Scale factor is ${scaleFactor}`);
  
        // Now apply that scale factor to the first image as a css transform
  
        // And the second image
        if (secondImage) {

          DEBUG_VERBOSE && console.log("[Debug] 23 Second image exists. Setting scale factors");

          firstImage.style.transformOrigin = STACKED ? `50% 50%` : `100% 50%`;
          firstImage.style.transform = `scale(${scaleFactor}`;

          secondImage.style.transformOrigin = STACKED ? `50% 50%` : `0% 50%`;
          secondImage.style.transform = `scale(${scaleFactor}`;
        } else {

          DEBUG_VERBOSE && console.log("[Debug] 23 Second image doesn't exist. Setting scale factors");

          firstImage.style.transformOrigin = STACKED ? `50% 50%` : `50% 50%`;
          firstImage.style.transform = `scale(${scaleFactor}`;
        }
  
        // Resize the container
        DEBUG_VERBOSE && console.log("Image is now " + firstImage.getBoundingClientRect().height);
  
        // This feels hacky, but not sure if there is an alternative. 
        // What I do here is get the size of the scaled image, and then resize it's parent container to be the size of that image plus the 
        // vertical margins. This is necessary, because otherwise the parent container remains at the height of the pre-scaled image
        // and ends up with lots of extra vertical space
        if (STACKED) {

          DEBUG_VERBOSE && console.log("[Debug] 24 Images are stacked");
          DEBUG_VERBOSE && console.log("[Debug] 25 Setting height of first image");
  
          let firstImageHeight = firstImage.getBoundingClientRect().height;
          const firstImageMargin = parseFloat(window.getComputedStyle(firstImage).marginTop) + parseFloat(window.getComputedStyle(firstImage).marginBottom);
          firstImageHeight = firstImageHeight + firstImageMargin;
          firstImage.parentElement.style.height = firstImageHeight + "px";
  
          if (secondImage) {
            DEBUG_VERBOSE && console.log("[Debug] 26 Setting height of second image");
            let secondImageHeight = secondImage.getBoundingClientRect().height;
            const secondImageMargin = parseFloat(window.getComputedStyle(secondImage).marginTop) + parseFloat(window.getComputedStyle(secondImage).marginBottom);
            secondImageHeight = secondImageHeight + secondImageMargin;
            secondImage.parentElement.style.height = secondImageHeight + "px";
          }
        }
        
        // Great job, team. Head back to base for debriefing and cocktails.
      };
  
      // Only run this resize code if we have two images, otherwise we can let css handle it all
  
      // Add a listener for window reiszes so that we scale the image if it changes
      DEBUG_VERBOSE && console.log("[Debug] 27 Adding resize listener");
      window.addEventListener("resize", () => {
        DEBUG_VERBOSE && console.log("[Debug] Window resize event fired");
        resizeImages();
      });

      // Run the resize on startup
      DEBUG_VERBOSE && console.log("[Debug] 28 Resizing images");
      resizeImages();
    }
  }

  addModalListeners() {
    DEBUG_VERBOSE && console.log("[Modal] Being asked to add modal listeners");
    DEBUG_VERBOSE && console.log("[Modal] Should add? ", this.isZoomable);  
    this.modalCloseBtn.addEventListener('click', this.hideModalInfo); 
    document.addEventListener('keydown', this.onEscapePressed);
  }

  showModal = (): Promise<void> => {

    DEBUG_VERBOSE && console.log("[Debug] 05 - Showing modal");

    const existingLoader = this.modalEl.querySelector('.chunkwc__floor-plate-v2__loader');
    if (!existingLoader) {
      DEBUG_VERBOSE && console.log("[Debug] 06 - No loader exists. Creating one");
      const loader = document.createElement('div');
      loader.classList.add('chunkwc__floor-plate-v2__loader');
      loader.innerHTML = '<span class="spinner"></span>'; 
      this.allowModals ? this.modalContent.appendChild(loader) : this.modalEl.appendChild(loader);
    }else{
      DEBUG_VERBOSE && console.log("[Debug] 06 - Loader already exists");
      DEBUG_VERBOSE && console.log("[Residence] Loader already visible");
    }

    return new Promise((resolve) => { 

      DEBUG_VERBOSE && console.log("[Debug] 07 - Animating modal");

      gsap.set(this.modalEl, { opacity: 1 });

      if (this.allowModals) {

        DEBUG_VERBOSE && console.log("[Debug] 08 - Allow modals");

        gsap.set(this.modalContent, { clearProps: 'all' });
        gsap.set(this.modalContent, { opacity: 0 }); 
        gsap.to(this.modalContent, {duration: 0.3, opacity: 1, onComplete: () => {
          this.body.classList.toggle('modal-no-scroll', true);
          resolve();
        }});
      } else {

        DEBUG_VERBOSE && console.log("[Debug] 08 - Don't allow modals");

        gsap.set(this.modalEl, { clearProps: 'all' });
        gsap.set(this.modalEl, { scale: 0.8, opacity: 0 }); 
        gsap.to(this.modalEl, {duration: 0.3, scale: 1, opacity: 1, onComplete: () => {
          resolve();
        }});
      }

    });

  }

  populateModal = (data: any) => {    
    DEBUG_VERBOSE && console.log("[Debug] 14 - Populating modal");

    if (this.allowModals) gsap.to(this.modalContent, {pointerEvents: 'auto'});
    gsap.to(this.modalCloseBtn, {opacity: 1, pointerEvents: 'auto', duration: 0});


    DEBUG_VERBOSE && console.log("[Debug] 15 - Setting title and description");
    this.residenceTitleEl.innerHTML = data.name || ' ';
    this.residenceDescriptionEl.innerHTML = data.description || ' ';

    // hardcoded text
    DEBUG_VERBOSE && console.log("[Debug] 16 - Setting display modes");
    if (this.allowModals) {
      DEBUG_VERBOSE && console.log("[Debug] 16a - allow Modals");
      if (data.interior_square_feet) {
          DEBUG_VERBOSE && console.log("[Debug] 16b - Interior square feet present");
          this.residenceEyebrow.style.display = "block";
          this.residenceDisclaimer.style.display = "block";
          this.residenceDimensions.style.display = "flex";
      } else {
        DEBUG_VERBOSE && console.log("[Debug] 16b - Interior square feet not present");
        this.residenceEyebrow.style.display = "none";
        this.residenceDisclaimer.style.display = "none";
        this.residenceDimensions.style.display = "none";
      }
    } else {

      DEBUG_VERBOSE && console.log("[Debug] 16a - Don't allow modals");
      this.residenceTypeEl.innerHTML = data.type || ' ';
      this.residenceTitleEl.innerHTML = data.name || ' ';
      this.residenceDescriptionEl.innerHTML = data.description || ' ';
    }

    if (data.interior_square_feet !== null && data.interior_square_feet !== undefined) {
      this.residenceInteriorLabelEl.style.display = this.residenceInteriorValueEl.style.display = 'inline'; 
      this.residenceInteriorValueEl.innerHTML = data.interior_square_feet;
    } else {
      this.residenceInteriorLabelEl.style.display = this.residenceInteriorValueEl.style.display = 'none'; 
    }

    if (data.exterior_square_feet) {
      this.residenceExteriorLabelEl.style.display = this.residenceExteriorValueEl.style.display = 'inline'; 
      this.residenceExteriorValueEl.innerHTML = data.exterior_square_feet;
      this.residenceExterior.style.display = "block";

    } else {

      this.residenceExteriorLabelEl.style.display = this.residenceExteriorValueEl.style.display = 'none'; 
      this.residenceExterior.style.display = "none";

    }

    DEBUG_VERBOSE && console.log("[Debug] 17 checking modals again");

    if (this.allowModals) {

      DEBUG_VERBOSE && console.log("[Debug] 17a modals allowed");

      this.residenceFloorplanWrapper.innerHTML = '';

      DEBUG_VERBOSE && console.log("[Debug] 17b modals allowed");

      if (data.floorplans) {

        DEBUG_VERBOSE && console.log("[Debug] 17c floorplan data included. Adding for each");

        data.floorplans.forEach(async (url: string, i) => {

          DEBUG_VERBOSE && console.log("[Debug] 17d Adding floorplan");

          let div = document.createElement('div'),
            image = document.createElement('img');

          div.classList.add('floorplan');

          DEBUG_VERBOSE && console.log("[Debug] 17e Adding first or second classlist");
          i == 0 ? div.classList.add('first') : div.classList.add('second');

          // add 'single' class if there's only one image
          if(data.floorplans.length == 1) {
            DEBUG_VERBOSE && console.log("[Debug] 17f Adding single classlist");
            div.classList.add('single');
          }

          // add image url
          image.src = url;

          // get SVG viewbox width to set max width of all images
          DEBUG_VERBOSE && console.log("[Debug] 17g Fetching SVG HTML");
          await fetch(url)
            .then(response => response.text())
            .then(svg => {
              DEBUG_VERBOSE && console.log("[Debug] 17h Parsing SVG HTML");

              const svgHtml = new DOMParser().parseFromString(svg, "text/xml");

              DEBUG_VERBOSE && console.log("[Debug] 17i Setting image width");

              let imageMaxWidth = svgHtml.querySelector('svg').viewBox.baseVal.width;
              image.style.maxWidth = `${imageMaxWidth}px`;
    
              DEBUG_VERBOSE && console.log("[Debug] 17j Appending image");
              div.appendChild(image);
              this.residenceFloorplanWrapper.append(div);
              DEBUG_VERBOSE && console.log("[Debug] 17k Image appended");
    
              // only handle image scaling once all images are populated 
              if(i == data.floorplans.length - 1) {
                DEBUG_VERBOSE && console.log("[Debug] 17l Images are populated. Applying scaling");
                this.handleFloorplansImageScaling();
              }
    
            });
        
        });

      }

      if (data.key) {
        this.residenceModalKey.style.display = 'inline';
        this.residenceModalKey.src = data.key; 
      }  else {
        this.residenceModalKey.style.display = 'none';
      }
    }

    let loader = this.modalEl.querySelector('.chunkwc__floor-plate-v2__loader');

    if (loader) {
      DEBUG_VERBOSE && console.log("[Residence] Removing loader");
      loader.remove();    
    }
  };

  onEscapePressed = (event: any) => {
    if (event.key === 'Escape') { 
      this.hideModalInfo();
    }
  };
  
  hideModalInfo = () => { 
    if (this.allowModals) this.body.classList.toggle('modal-no-scroll', false);

    // let loader = this.modalEl.querySelector('.chunkwc__floor-plate-v2__modal__loader');
    // if (loader) loader.remove();        

    if (this.allowModals) { 
      gsap.to(this.modalContent, {duration: 0.3, opacity: 0, pointerEvents: 'none'}); 
    } 
    gsap.to(this.modalEl, {duration: 0, opacity: 0}); 

  };

  removeModalListeners() {
    this.modalCloseBtn.removeEventListener('click', this.hideModalInfo);
    document.removeEventListener('keydown', this.onEscapePressed);
  }
  
  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	RESIZE
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  removeAllListeners() {
    this.removeSVGListeners(); /* Removes Zoom, Drag and Residence listeners */
    this.removeModalListeners();
  }

  removeSVGListeners() {
    this.removeResidenceListeners();  
    this.removeZoomListeners();
    this.removeDragListeners();
  
  }

  addResizeListener() {
    this.handleResize = this.onResize.bind(this); 
    window.addEventListener('resize', this.handleResize);  
  }

  onResize() {

    clearTimeout(this.debouncedResize);
    this.debouncedResize = window.setTimeout(() => {

      DEBUG_VERBOSE && console.log("[Resize] Removing all listeners");
      this.removeAllListeners();

      DEBUG_VERBOSE && console.log("[Resize] Setting zoomable and clickable flags");
      this.isZoomable = window.innerWidth < ZOOMABLE_MAX_WIDTH;
      DEBUG_VERBOSE && console.log("[Resize] Zoomable: ", this.isZoomable);    

      /* If the image is no longer zoomable, reset it to defaults */
      if (!this.isZoomable) {
        this.resetDrag();
        this.resetZoom();
      }

      this.requiresClick = window.innerWidth < CLICKABLE_MAX_WIDTH || this.allowModals; 
      DEBUG_VERBOSE && console.log("[Resize] Clickable: ", this.requiresClick);

      this.addDragListeners();
      this.addZoomListeners();
      this.addResidenceListeners();
      this.addModalListeners();

    },250);

  }

  removeResizeListener() {
    window.removeEventListener('resize', this.handleResize);
  }

  async updateSVG(desktopUrl: string) {

    // TODO: Further optimise this

    let svg: string;

    let ready: boolean = false; 

    this.removeSVGListeners();

    try {
      const desktopDiv = document.querySelector('.chunkwc__floor-plate-v2__svg__desktop-image');

      // Fade out the current SVG
      gsap.to(desktopDiv, {duration: 0.5, opacity: 0, onComplete: () => {

        // As the fade is async, let's check if the new SVG is already ready
        // and if it is, populate it and fade up
        if (ready) {
          desktopDiv.innerHTML = svg;
          this.svg = this.svgEl.querySelector("svg") as SVGSVGElement;
          this.resetZoom();
          this.resetDrag();
          gsap.to(desktopDiv, {duration: 0.5, opacity: 1, onComplete: () => {
            this.addSVGListeners();        
          }});
        } else {
          // Otherwise, set the ready flag
          ready = true;
        }    

      }});

      let desktopRes = await fetch(desktopUrl);
      if (!desktopRes.ok) {
          throw new Error(`HTTP error! status: ${desktopRes.status}`);
      } else {
        svg = await desktopRes.text();

        // If the fade out above has aleady completed, the ready flag will 
        // be set, and we should just fade up as soon as the SVG is loaded
        if (ready) {
          desktopDiv.innerHTML = svg;
          this.svg = this.svgEl.querySelector("svg") as SVGSVGElement;
          this.resetZoom();
          this.resetDrag();
          gsap.to(desktopDiv, {duration: 0.5, opacity: 1, onComplete: () => {
            this.addSVGListeners();
          }});
        }else {
          // If not, set the flag, so that the onComplete event above will 
          // fire once the initial fade out is complete
          ready = true;
        }  
      }

    } catch (error) {
        console.error('Error fetching the SVG:', error);
    }

  }

}

customElements.define(TAG_NAME, FloorPlateBlockV2);
