import { Logger } from "../../utils/Logger";
import "./Ticker.scss";
import gsap from "gsap";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[TickerBlock]";
const TAG_NAME = "chunkwc-ticker";

// ////////////////////////////////////////////////////////////////////

export default class TickerBlock extends HTMLElement {
  private _anim: gsap.core.Tween;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    /* Let's get the width of the user's screen, as that is the widest the ticker can ever be */
    const speed: number = this.dataset.speed
      ? parseInt(this.dataset.speed)
      : 50;

    /* Get the wrapper element and duplicate the content inside it until it's as wide as the screen */
    const wrapper = this.querySelector(".ticker_wrapper") as HTMLElement;
    const content = wrapper.querySelector(".ticker_content") as HTMLElement;
    const html = content.innerHTML;

    if (this.dataset.pauseOnHover) {
      this.setupPauseOnHover();
    }

    if (wrapper) {
      /* Keep adding blocks until we are at screen width */
      const MAX_DUPLICATES = 10;
      let count = 0;
      while (count < MAX_DUPLICATES && wrapper.offsetWidth < screen.width) {
        content.innerHTML += html;
        count++;
      }

      if (count === MAX_DUPLICATES) {
        Logger.error(
          `${CLASS_NAME} Aborted duplication early. Your css is likely breaking the size calculation`
        );
      }

      /* Cache the width of the content, as we'll need it later */
      const contentWidth = wrapper.offsetWidth;

      /* Duplicate so that we have two blocks a screen wide */
      wrapper.innerHTML += wrapper.innerHTML;

      /* Animate each of the ticket items to -100% of its position, which will cause it to scroll left. The 
		   repeat then causes the ticker to jump back to it's original position and start again, which happens
		   seamlessly, giving the impression of a continuous loop */
      this._anim = gsap.to(".ticker_content", {
        duration: contentWidth / speed,
        xPercent: -100,
        ease: "none",
        repeat: -1,
      });
      // }
    }
  }

  setupPauseOnHover() {
    this.addEventListener("mouseenter", () => {
      this._anim.pause();
    });
    this.addEventListener("mouseleave", () => {
      this._anim.resume();
    });
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}
}

customElements.define(TAG_NAME, TickerBlock);
