import './Carousel.scss';

import gsap from 'gsap';

/**
 * SWIPER API
 * https://swiperjs.com/swiper-api
 */

import Swiper, { Navigation, EffectFade, Autoplay, SwiperOptions, Pagination, Scrollbar, Manipulation } from 'swiper';
// import Swiper and modules styles
import 'swiper/css/bundle';

import AttributesHelper from '../../config/AttributesHelper';
import { SwiperClass } from '../../config/constants';

// configure Swiper to use modules
Swiper.use([Navigation, EffectFade, Autoplay, Pagination, Scrollbar, Manipulation]);

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[CarouselBlock]';
const TAG_NAME = 'chunkwc-carousel';

// 'bullets' | 'fraction' | 'progressbar' | 'custom'

const enum PaginationType {
	Bullets = 'bullets',
	BulletsDynamic = 'bullets-dynamic',
	Fraction = 'fraction',
	Progressbar = 'progressbar',
}

const enum Attribute {
	Pagination = 'pagination',
	Navigation = 'navigation',
	Scrollbar = 'scrollbar',
	Autoplay = 'autoplay',
	Speed = 'speed',
	Loop = 'loop',
	Effect = 'effect',
	SpaceBetween = 'space-between',
	SlidesPerView = 'slides-per-view',
	CenteredSlides = 'centered-slides',
	InitialSlide = 'initial-slide',
	Type = 'type',
}

enum Effect {
	Fade = 'fade',
}

const selectorCaption = 'figcaption';

// ////////////////////////////////////////////////////////////////////

export default class Carousel extends HTMLElement {
	private swiperInstance!: Swiper;
	private attr!: AttributesHelper;
	private _currentSlide: HTMLSpanElement;

	// Default Swiper Options
	private swiperOptions: SwiperOptions = {
		loop: false,
		speed: 750,
		pagination: false,
		scrollbar: false,
		slidesPerView: 1,
	};

	private initialSlides: HTMLElement[];
	private slidesContainer: HTMLElement;

	// ////////////////////////////////////////////////////////////////////

	constructor() {
		super();
	}

	init() {
		// Store the slides before Swiper does anything to it
		this.slidesContainer = this.querySelector('.swiper-wrapper') as HTMLElement;
		this._currentSlide = this.querySelector('.chunkwc__carousel__current-slide');

		if (this.slidesContainer) {
			const children = Array.from(this.slidesContainer.children) as HTMLElement[];

			this.initialSlides = [...children];
		} else {
			console.warn(`No wrapper - Unable to process children.`);
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		this.attr = new AttributesHelper(this);
		this.initSwiper();
		this.updateCurrentSlide();
	}

	// ////////////////////////////////////////////////////////////////////
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'connectedCallback');
		this.init();
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'disconnectedCallback');

		this.swiperInstance.destroy(true, true);
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'adoptedCallback');
	}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'attributeChangedCallback');
	}

	// ////////////////////////////////////////////////////////////////////

	public navigateToSlide(slide: number) {
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'Navigating to slide ' + slide);
		if (this.swiperOptions.loop === true) {
			this.swiperInstance.slideToLoop(slide, 0, false);
		} else {
			this.swiperInstance.slideTo(slide, 0, false);
		}
	}

	initSwiper() {
		// Handle attributes

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// Scrollbar

		const scrollbar = this.attr.getAttribute(Attribute.Scrollbar);

		if (scrollbar === true)
			this.swiperOptions.scrollbar = {
				el: SwiperClass.Scrollbar,
				draggable: true,
			};

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// Navigation

		const navigation = this.attr.getAttribute(Attribute.Navigation);

		switch (navigation) {
			case true:
			case null:
				// No option set but default to show navigation
				this.swiperOptions.navigation = {
					nextEl: SwiperClass.NavigationNext,
					prevEl: SwiperClass.NavigationPrev,
				};
				break;

			case false:
				this.swiperOptions.navigation = false;

			default:
				break;
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\
		// space-between

		const initialSlide = this.attr.getAttribute(Attribute.InitialSlide);

		if (typeof initialSlide === 'number') {
			this.swiperOptions.initialSlide = initialSlide;
		}


		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		const pagination = this.attr.getAttribute(Attribute.Pagination);

		switch (pagination) {
			case false:
				this.swiperOptions.pagination = false;
				break;

			// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
			// Default to bullet pagination

			case true:
			case PaginationType.Bullets:
				this.swiperOptions.pagination = {
					el: SwiperClass.Pagination,
					type: PaginationType.Bullets,
					clickable: true,
					renderBullet: function (index, className) {
						return `<span class="${className}">${index <= 8 ? 0 : ''}${index + 1}</span>`;
					},
				};
				break;

			case PaginationType.BulletsDynamic:
				this.swiperOptions.pagination = {
					el: SwiperClass.Pagination,
					type: PaginationType.Bullets,
					clickable: true,
					dynamicBullets: true,
				};
				break;

			// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

			case PaginationType.Fraction:
				this.swiperOptions.pagination = {
					el: SwiperClass.Pagination,
					type: PaginationType.Fraction,
				};
				break;

			// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

			case PaginationType.Progressbar:
				this.swiperOptions.pagination = {
					el: SwiperClass.Pagination,
					type: PaginationType.Progressbar,
				};
				break;

			// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

			case null:
				break;

			// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

			default:
				console.warn(`Pagination attribute NOT handled - pagination: `, pagination);
				break;
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// autoplay

		const autoplay = this.attr.getAttribute(Attribute.Autoplay);

		if (typeof autoplay === 'number') {
			this.swiperOptions.autoplay = {
				delay: autoplay,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
				reverseDirection: false,
				stopOnLastSlide: false,
				waitForTransition: true,
			};
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// speed

		const speed = this.attr.getAttribute(Attribute.Speed);

		if (typeof speed === 'number') this.swiperOptions.speed = speed;

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// loop

		const loop = this.attr.getAttribute(Attribute.Loop);

		if (loop === true) this.swiperOptions.loop = true;

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// fade

		const effect = this.attr.getAttribute(Attribute.Effect);

		if (effect === Effect.Fade) {
			this.swiperOptions.effect = Effect.Fade;
			this.swiperOptions.fadeEffect = {
				crossFade: true,
			};
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// slides-per-view

		// const slidesPerView = this.attr.getAttribute(Attribute.SlidesPerView);

		// if (typeof slidesPerView === 'number') {
		// 	this.swiperOptions.slidesPerView = window.innerWidth < 768 ? 1 : slidesPerView;
		// }

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// centered-slides

		const centeredSlides = this.attr.getAttribute(Attribute.CenteredSlides);

		if (centeredSlides === true) this.swiperOptions.centeredSlides = centeredSlides;

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -\
		// space-between

		const spaceBetween = this.attr.getAttribute(Attribute.SpaceBetween);

		if (typeof spaceBetween === 'number') {
			this.swiperOptions.spaceBetween = spaceBetween;
		}

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		DEBUG_VERBOSE && console.log('this.swiperOptions', this.swiperOptions);

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		const container = this.querySelector('.swiper') as HTMLElement;
		const galleryCarouselType = this.attr.getAttribute(Attribute.Type);

		if (galleryCarouselType === 'archive') {
			this.swiperOptions.slidesPerView = 1;
			this.swiperOptions.breakpoints = {
				'768': {
					slidesPerView: 1
				},
				'1920': {
					slidesPerView: 1
				}
			};
		}

		this.swiperInstance = new Swiper(container, this.swiperOptions);

		this.swiperInstance.on('slideChange', (e) => {

			this.updateCurrentSlide();

			if(this.querySelector('.swiper-pagination') !== null) {
				let bullets = Array.from(this.querySelector('.swiper-pagination').children);
	
				bullets.forEach(bullet => {
					// @ts-ignore
					let number = bullet.innerText;
	
					function scrollTo(el : any) {
						const elRight = el.offsetLeft + el.offsetWidth;
						const elLeft = el.offsetLeft;
					
						const elParentRight = el.parentNode.offsetLeft + el.parentNode.offsetWidth;
						const elParentLeft = el.parentNode.offsetLeft;
					
						// check if right side of the element is not in view
						if (elRight > elParentRight + el.parentNode.scrollLeft) {
						  el.parentNode.scrollLeft = elRight - elParentRight;
						}
					
						// check if left side of the element is not in view
						else if (elLeft < elParentLeft + el.parentNode.scrollLeft) {
						  el.parentNode.scrollLeft = elLeft - elParentLeft;
						}
					}
	
					// // remove prepended on poagination numbers less than 10
					if (number.split('')[0] == 0){
						number = number.split('')[1];
					}
	
					// in case loop is activated, we need to account for the additional indexes swiper outputs
					if(this.swiperInstance.activeIndex == 0){
						//@ts-ignore
						scrollTo(bullets[bullets.length - 1]);
					}
	
					if(this.swiperInstance.activeIndex == bullets.length + 1){
						//@ts-ignore
						scrollTo(bullets[0]);
					}
	
					// // scroll to bullet that matches swiper instance index
					if(number - 1  == this.swiperInstance.activeIndex) {
						scrollTo(bullet);
					}
				})
			}
		});

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		this.swiperInstance.on('slideChange', () => {
			this.handleSlideCaptions();
		});

		this.handleSlideCaptions();

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// archive carousel

		if (galleryCarouselType === 'archive') {
			const termsContainer = document.querySelector('.gallery__terms');

			const terms = Array.from(termsContainer.children) as HTMLElement[];

			terms.forEach((term) => {
				term.onclick = () => {
					const { cat: selectedCat } = term.dataset;


					this.swiperInstance.removeAllSlides();

					let filteredSlides = this.initialSlides.filter((slide) => slide.classList.contains(selectedCat) || selectedCat == '*');
					filteredSlides = filteredSlides.slice().reverse();

					this.swiperInstance.addSlide(0, filteredSlides);

					// add active state to the selected category list item
					terms.forEach((term) => {
						term.classList.toggle('is-active', term.dataset.cat === selectedCat);
					});

					this.swiperInstance.updateSlides();

					if (this.swiperOptions.loop === true) {
						this.swiperInstance.slideToLoop(0, 0, false);
					} else {
						this.swiperInstance.slideTo(0, 0, false);
					}

					this.resetSlideCaptions();
					this.handleSlideCaptions();
				};
			});
		}
	}

	updateCurrentSlide() {
		const { isBeginning } = this.swiperInstance;
		  let { realIndex } = this.swiperInstance;
		  // fix a bug that index is 0 for first and second one
		  if (!isBeginning) {
			realIndex += 1;
		  }

		if(this._currentSlide) {
			this._currentSlide.innerText = `${this.swiperInstance.activeIndex < 10 ? 0 : ''}${ realIndex } `;
		}
	}

	resetSlideCaptions() {
		const { slides } = this.swiperInstance;
		let slideEls = Array.from(slides) as HTMLElement[];
		slideEls.forEach((slide) => {
			const captionEl = slide.querySelector(selectorCaption);

			if (!captionEl) {
				// Abort if no caption
				return;
			}

			gsap.set(captionEl, {
				opacity: 0,
			});
		});
	}

	handleSlideCaptions() {
		const { slides, realIndex } = this.swiperInstance;

		let slideEls = Array.from(slides) as HTMLElement[];

		// Only use the slides that haven't been disabled by filtering.
		slideEls = slideEls.filter((slide) => slide.style.display != 'none');

		slideEls.forEach((slide, index) => {
			const swiperSlideIndexStr = slide.dataset.swiperSlideIndex as string;

			// If set to loop there's a data-swiper-slide-index value. If that exists we use that otherwiase use the loop index.
			const indexTest = swiperSlideIndexStr ? parseInt(swiperSlideIndexStr) : index;

			const captionEl = slide.querySelector(selectorCaption);

			if (!captionEl) {
				// Abort if no caption
				return;
			}

			if (indexTest === realIndex) {
				gsap.to(captionEl, {
					opacity: 1,
					delay: 0.5,
					duration: 0.5,
				});
			} else {
				gsap.to(captionEl as HTMLElement, {
					opacity: 0,
					duration: 0.125,
				});
			}
		});
	}
}

customElements.define(TAG_NAME, Carousel);
