import "./Hero.scss";

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css/bundle";

import GLSlideshow from "GLSlideshow";
import * as glTransitions from "gl-transitions";

// import { Transition01 } from './HeroWebGlTransition01';
// import { Transition02 } from './HeroWebGlTransition02';
// import { Transition03 } from './HeroWebGlTransition03';
// import { Transition04 } from './HeroWebGlTransition04';
// import { Transition05 } from './HeroWebGlTransition05';
// import { Transition06 } from './HeroWebGlTransition06';
// import { Transition07 } from './HeroWebGlTransition07';
// import { Transition08 } from './HeroWebGlTransition08';

// @ts-ignore
import Player from "@vimeo/player";
import YouTubePlayer from "youtube-player";
import gsap from "gsap";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[HeroBlock]";
const TAG_NAME = "chunkwc-hero";

enum HeroType {
  video,
  image,
  webgl,
}

enum VideoProvider {
  Vimeo,
  YouTube,
  Direct,
}

// enum CustomShader {
// 	Bounce = 'Bounce',
// 	BowTieHorizontal = 'BowTieHorizontal',
// 	BowTieVertical = 'BowTieVertical',
// 	ButterflyWaveScrawler = 'ButterflyWaveScrawler',
// 	CircleCrop = 'CircleCrop',
// 	ColourDistance = 'ColourDistance',
// 	CrazyParametricFun = 'CrazyParametricFun',
// 	CrossZoom = 'CrossZoom',
// 	Directional = 'Directional',
// 	DoomScreenTransition = 'DoomScreenTransition',
// 	Dreamy = 'Dreamy',
// 	DreamyZoom = 'DreamyZoom',
// 	GlitchDisplace = 'GlitchDisplace',
// 	GlitchMemories = 'GlitchMemories',
// 	GridFlip = 'GridFlip',
// 	InvertedPageCurl = 'InvertedPageCurl',
// 	LinearBlur = 'LinearBlur',
// 	Mosaic = 'Mosaic',
// 	PolkaDotsCurtain = 'PolkaDotsCurtain',
// 	Radial = 'Radial',
// 	SimpleZoom = 'SimpleZoom',
// 	StereoViewer = 'StereoViewer',
// 	Swirl = 'Swirl',
// 	WaterDrop = 'WaterDrop',
// 	ZoomInCircles = 'ZoomInCircles',
// 	angular = 'angular',
// 	burn = 'burn',
// 	cannabisleaf = 'cannabisleaf',
// 	circle = 'circle',
// 	circleopen = 'circleopen',
// 	colorphase = 'colorphase',
// 	crosshatch = 'crosshatch',
// 	crosswarp = 'crosswarp',
// 	cube = 'cube',
// 	directionalwarp = 'directionalwarp',
// 	directionalwipe = 'directionalwipe',
// 	displacement = 'displacement',
// 	doorway = 'doorway',
// 	fade = 'fade',
// 	fadecolor = 'fadecolor',
// 	fadegrayscale = 'fadegrayscale',
// 	flyeye = 'flyeye',
// 	heart = 'heart',
// 	hexagonalize = 'hexagonalize',
// 	kaleidoscope = 'kaleidoscope',
// 	luma = 'luma',
// 	luminance_melt = 'luminance_melt',
// 	morph = 'morph',
// 	multiply_blend = 'multiply_blend',
// 	perlin = 'perlin',
// 	pinwheel = 'pinwheel',
// 	pixelize = 'pixelize',
// 	polar_function = 'polar_function',
// 	randomsquares = 'randomsquares',
// 	ripple = 'ripple',
// 	rotate_scale_fade = 'rotate_scale_fade',
// 	squareswire = 'squareswire',
// 	squeeze = 'squeeze',
// 	swap = 'swap',
// 	undulatingBurnOut = 'undulatingBurnOut',
// 	wind = 'wind',
// 	windowblinds = 'windowblinds',
// 	windowslice = 'windowslice',
// 	wipeDown = 'wipeDown',
// 	wipeLeft = 'wipeLeft',
// 	wipeRight = 'wipeRight',
// 	wipeUp = 'wipeUp',
// }

// ////////////////////////////////////////////////////////////////////

/* 

Sample Video URLs
Vimeo - https://vimeo.com/748727633
YouTube - https://www.youtube.com/watch?v=VI8Wj5EwoRM
Direct - https://player.vimeo.com/progressive_redirect/playback/745254677/rendition/1080p/file.mp4?loc=external&signature=5874a357074bf03cc7088b0878e9f55425cda221e2b3ae4a59cd5edab13a8550


*/

export default class HeroBlock extends HTMLElement {
  private _el: HTMLElement;
  private _arrow: HTMLElement;
  // private _swiper: Swiper;
  private _provider: VideoProvider;
  private _heroType: HeroType;

  private _loopPoint: number;
  private _startPoint: number;
  private _player: Player | typeof YouTubePlayer;
  private _iframe: HTMLIFrameElement;
  private _overlay: HTMLElement;
  private _started: boolean = false;
  private _images: number = 0;
  private _autoplay: any;
  private _playButton: HTMLDivElement;
  private _poster: HTMLDivElement;
  private _video: HTMLDivElement;


  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
    this._el = this;
  }

  init() {
    this._heroType = HeroType[this.dataset.type as keyof typeof HeroType];

    this._images = parseInt(this.dataset.numImages);
    this._autoplay = this.dataset.autoplay;
    this._playButton = this.querySelector('.chunkwc__hero__play');
    this._video = this.querySelector('.chunkwc__hero__video');
    this._poster = this.querySelector('.chunkwc__hero__poster');
    this._overlay = this.querySelector('.chunkwc__hero__overlay');

    switch (this._heroType) {
      case HeroType.video:
        this.initVideoHero();
        break;
      case HeroType.image:
        this.initImageHero();
        break;
      case HeroType.webgl:
        this.initWebGLHero();
        break;
      default:
        console.error("No hero type specified");
    }

    this.handleScroll();
    this.handlePlayButtonClick();
    this.handleVideoClick();
  }

  handleVideoClick() {
    if(this._overlay && !this.dataset.controls){
      this._overlay.addEventListener('click', async () => {
        if(await this.isPlaying){
          this._player.pause();
        } else {
          this._player.play();
        }
      });
    }
  }

  get isPlaying() {
      return this._player.getPaused().then((paused: boolean) => {
        return !paused;
      });
  }

  initImageHero() {
    Swiper.use([Autoplay, Navigation, Pagination, EffectFade]);

    new Swiper(this._el.querySelector(".swiper") as HTMLElement, {
      autoplay: this._images > 1 ? {
        delay: 5000,
      } : false,
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 0,
      effect: this._el.dataset.effect,
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
    });
  }

  async initWebGLHero() {
    const imageUrls = this._el.dataset.images.split(",");

    if (this._images > 1) {
      const shader = glTransitions.find((shader: any) => {
        return shader.name === this._el.dataset.style;
      });

      if (shader) {
        GLSlideshow.addShader(
          "customShader",
          shader.glsl,
          shader.defaultParams
        );

        new GLSlideshow(imageUrls, {
          canvas: this._el.querySelector(".chunkwc__hero__canvas"),
          width: 1920,
          height: 1080,
          duration: 1000,
          interval: 2000,
          effect: "customShader",
        });
      } else {
        console.error(`No shader found with name ${this._el.dataset.style}`);
      }

      /*
		'crossFade';
		'crossZoom';
		'directionalWipe';
		'wind';
		'ripple';
		'pageCurl';
		*/
    } else {
      // Get references to the canvas and its context
      var canvas = this._el.querySelector<HTMLCanvasElement>(
        ".chunkwc__hero__canvas"
      );

      canvas.width = 1920;
      canvas.height = 1080;

      var context = canvas.getContext("2d");

      // Create an image element
      var image = new Image();

      // Set the source of the image
      image.src = imageUrls[0];

      // Wait for the image to load
      image.onload = function () {
        // Calculate the scaling factors for the image to cover the canvas
        var scaleX = canvas.width / image.width;
        var scaleY = canvas.height / image.height;
        var scale = Math.max(scaleX, scaleY);

        // Calculate the position to center the scaled image on the canvas
        var offsetX = (canvas.width - image.width * scale) / 2;
        var offsetY = (canvas.height - image.height * scale) / 2;
        context.imageSmoothingEnabled = true;

        // Draw the scaled image on the canvas
        context.drawImage(
          image,
          offsetX,
          offsetY,
          image.width * scale,
          image.height * scale
        );
      };
    }
  }

  initVideoHero() {
    this._iframe = this.querySelector("iframe");
    this._loopPoint = this.dataset.loop ? parseInt(this.dataset.loop) : 0;
    this._startPoint = this.dataset.start ? parseInt(this.dataset.start) : 0;

    switch (this._el.dataset.provider) {
      case "direct":
        this._provider = VideoProvider.Direct;
        this.initDirectVideo();
        break;
      case "youtube":
        this._provider = VideoProvider.YouTube;
        this.initYouTubeVideo();
        break;
      case "vimeo":
        this._provider = VideoProvider.Vimeo;
        this.initVimeoVideo();
        break;
    }

    DEBUG_VERBOSE &&
      console.log(
        CLASS_NAME,
        "Initalised video hero with provider",
        this._provider
      );
  }

  handleScroll() {
    this._arrow = this.querySelector('.chunkwc__hero__arrow');
          
    document.addEventListener('scroll', () => {
      if(document.documentElement.scrollTop > 50){
        if (this._arrow) gsap.to(this._arrow, {
          opacity: 0
        })

        return;
      }

      if (this._arrow) gsap.to(this._arrow, {
        opacity: 1
      })
    })
  }

  handlePlayButtonClick() {
    if(!this._autoplay && this._playButton){

      this._playButton.addEventListener('click', () => {
        this._playButton.classList.toggle('is-active');
        if (this._poster) this._poster.classList.add('is-active');


        if(this._video !== null) {
          this._video.classList.add('is-active');
        }

        this._playButton.classList.contains('is-active') ? this._player.play() : this._player.pause();
      })
    }
  }

  initDirectVideo() {
    this._player = this.querySelector("video");
    if (this._loopPoint > 0) {
      setInterval(() => {
        const time = this._player.currentTime;
        if (!this._started) {
          this._started = true;
        }
        if (time >= this._loopPoint) {
          this.loopDirectVideo();
        }
      }, 5);
    }

    this._player.addEventListener("ended", () => {
      this.loopDirectVideo();
    });
  }

  loopDirectVideo() {
    this._player.currentTime = this._startPoint;
    this._player.play();
  }

  initVimeoVideo() {
    this._player = new Player(this._iframe);

    this._player.on("loaded", () => {
      DEBUG_VERBOSE && console.log("LOADING VIDEO");
      if(this._autoplay) {
        this._player.play();
        this._playButton.classList.add('is-active');
      }
    });

    this._player.on("bufferstart", () => {
      DEBUG_VERBOSE && console.log("BUFFERING STARTED");
    });

    this._player.on("timeupdate", (update: any) => {
      if (!this._started) {
        // this.toggleOverlay(false);
        this._started = true;
        this._playButton.classList.add('is-active');
      }
      if (
        this._loopPoint > 0 &&
        parseInt(update.seconds, 10) >= this._loopPoint
      ) {
        this.loopVimeoVideo();
      }
    });

    // This has been switched for the timeupdate event so that it doesn't fire
    // until the video has _started_ playback. Hiding the poster on
    // the play event results in a brief flash of no content
    //
    this._player.on("play", () => {
      DEBUG_VERBOSE && console.log(CLASS_NAME, `Video playing`);
      this._playButton.classList.add('is-active');
      // this.toggleOverlay(false);
    });

    this._player.on("pause", () => {
      this._playButton.classList.remove('is-active');
      // this.toggleOverlay(false);
    });

    this._player.on("ended", () => {
      this.loopVimeoVideo();
    });
  }

  loopVimeoVideo() {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `Looping Vimeo video to ${this._startPoint}s`);
    this._player
      .setCurrentTime(this._startPoint)
      .then(() => {
        this._player.play();
      })
      .catch(() => {
        console.error("Error looping video");
      });
  }

  initYouTubeVideo() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Setting up YouTube video");
    this._player = YouTubePlayer(this._iframe);

    this._player.on("stateChange", (event: any) => {
      /*

			YouTube Status Codes

			-1 (unstarted)
			0 (ended)
			1 (playing)
			2 (paused)
			3 (buffering)
			5 (video cued).

			*/

      switch (event.data) {
        case 1:
          DEBUG_VERBOSE && console.log(CLASS_NAME, `Video playing`);
        // this.toggleOverlay(false);
      }
    });

    if (this._loopPoint > 0) {
      setInterval(() => {
        this._player.getCurrentTime().then((time: any) => {
          if (!this._started) {
            // this.toggleOverlay(false);
            this._started = true;
          }

          if (time >= this._loopPoint) {
            this.loopYouTubeVideo();
          }
        });
      }, 25);
    }

    this._player.on("stateChange", (event: any) => {
      if (event.data == 0) {
        this.loopYouTubeVideo();
      }
    });
  }

  loopYouTubeVideo() {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `Looping YouTube video to ${this._startPoint}s`);
    this._player.seekTo(this._startPoint);
    this._player.playVideo();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}
}

customElements.define(TAG_NAME, HeroBlock);
