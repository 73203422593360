import "./Dollhouse-v1.scss";

import Swiper, { Navigation, SwiperOptions } from "swiper";

Swiper.use([Navigation]);

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[DollhouseBlock v1]";
const TAG_NAME = "chunkwc-dollhouse-v1";

// ////////////////////////////////////////////////////////////////////

export default class DollhouseBlockV1 extends HTMLElement {
  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  imageContainer: HTMLElement;
  svg: SVGElement;
  image: HTMLImageElement;

  scale: number;

  legendItems: HTMLElement[];
  swiper: Swiper;
  pinsContainer: HTMLElement;
  markerWidth: number;
  markerHeight: number;
  pins: HTMLElement[] = [];

  variableColumSizes: boolean = false;
  columnSizes: number | number[];

  initialWidth: number;
  initialHeight: number;

  // Default Swiper Options
  private swiperOptions: SwiperOptions = {
    loop: false,
    speed: 750,
    // pagination: {
    // 	el: '.swiper-pagination',
    // 	type: 'progressbar',
    // },
    navigation: {
      nextEl: this.querySelector(".swiper-button-next") as HTMLElement,
      prevEl: this.querySelector(".swiper-button-prev") as HTMLElement,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      dragSize: 59,
    },
    breakpoints: {
      1: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2, // slide two at a time
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
    },
  };

  init() {
    this.initDOMElements();

    this.initLegendItems();

    this.initSwiper();

  }

  destroy() {}

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initDOMElements() {
    // Get a handle to all the elemnts we need

    this.imageContainer = this.querySelector(".chunkwc-dollhouse-v1__image");
    this.image = this.imageContainer.querySelector("img") as HTMLImageElement;
    this.svg = this.imageContainer.querySelector("svg") as SVGSVGElement;
    this.pinsContainer = this.querySelector(".chunkwc-dollhouse-v1__image-pins");

    // Wait for the image to load before calculating scale
    if (this.image.complete) {
      DEBUG_VERBOSE && console.log("Image was loaded before listener applied");
      this.setInitialScale();
    } else {
      DEBUG_VERBOSE && console.log("Waiting for image to load");
      this.image.onload = () => {
        DEBUG_VERBOSE && console.log("Image is loaded");
        this.setInitialScale();
      };
    }

    this.legendItems = Array.from(
      this.querySelectorAll("[data-location]")
    ) as HTMLElement[];
  }

  setInitialScale() {
    this.scale = this.image.clientWidth / this.image.naturalWidth;

    DEBUG_VERBOSE &&
      console.log(
        `[Dollhouse] The dollhouse image is ${this.image.naturalWidth}x${this.image.naturalHeight}, scaled to ${this.image.clientWidth}x${this.image.clientHeight}`
      );
    DEBUG_VERBOSE &&
      console.log(`[Dollhouse] Initial image scale is ${this.scale}`);

    this.createPins();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  createPins() {
    // Get the annotations from the image and re-create them in the DOM layer

    // First, set the SVG container to be the natural size of the image
    this.svg.style.width = this.pinsContainer.style.width =
      this.image.naturalWidth + "px";
    this.svg.style.height = this.pinsContainer.style.height =
      this.image.naturalHeight + "px";

    // console.log(this.svg);

    let markers = Array.from(
      this.svg.querySelectorAll('[class^="location-"]')
    ) as SVGGElement[];

    markers.forEach((marker: SVGElement) => {
      // Get the id of the annotation from the SVG class
      let id = marker.classList[0].replace("location-", "");

      // Get the position of the marker in the SVG, offseting it by the position of the image on the page
      let markerX =
        marker.getBoundingClientRect().left -
        this.image.getBoundingClientRect().left;
      let markerY =
        marker.getBoundingClientRect().top -
        this.image.getBoundingClientRect().top;

      // Set the width and height to be identical to prevent any rounding errors
      this.markerWidth = this.markerHeight =
        marker.getBoundingClientRect().width;

      // Create the pin
      this.createPin(markerX, markerY, id);
    });

    // Scale the pins to now match the image
    this.scalePins();

    // Add a listener so that we resize the pins on window resizing
    window.addEventListener("resize", () => {
      this.scalePins();
    });
  }

  createPin(x: number, y: number, id: string) {
    let pin = document.createElement("div");
    pin.dataset.aos = "fade";

    let delay = (100 * parseInt(id)).toString();
    pin.dataset.aosDelay = delay;
    pin.classList.add("chunkwc-dollhouse-v1__pin");
    pin.classList.add("location-" + id);
    pin.style.top = y + "px";
    pin.style.left = x + "px";

    // Set the pin to be the same width and height as the SVG marker
    pin.style.width = `${this.markerWidth}px`;
    pin.style.height = `${this.markerHeight}px`;

    let label = document.createElement("p");
    label.innerHTML = id;

    pin.appendChild(label);

    pin.addEventListener("click", () => {
      this.setActivePin(id);
    });

    this.pins.push(pin);
    this.pinsContainer.appendChild(pin);
  }

  scalePins() {

    let scale = this.image.clientWidth / this.image.naturalWidth;
    let scaledDiameter = this.pins[0].clientWidth * scale;
    this.pinsContainer.style.transform = `scale(${scale})`;


    let targetDiameter: number, pinScale: number;
    if (window.innerWidth <= 768) {
      targetDiameter = 21;
      pinScale = targetDiameter / scaledDiameter;
    } else if (window.innerWidth >= 1200) {
      targetDiameter = 21;
      pinScale = targetDiameter / scaledDiameter;
    }

    if (targetDiameter) {
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Pins scaled up by the container are currently ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Need to scale pins by a factor of ${pinScale} to maintain a size of ${targetDiameter}`
        );

      this.pins.forEach((pin) => {
        pin.style.transform = `scale(${pinScale})`;
      });

      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] Pins are now ${scaledDiameter} scaled to ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
    } else {
      DEBUG_VERBOSE &&
        console.log(
          `[Dollhouse] No scaling needed. Pins are currently ${
            this.pins[0].getBoundingClientRect().width
          }`
        );
    }

    this.pinsContainer.style.transform = `scale(${scale})`;
  }

  setActivePin(idStr: string) {
    this.legendItems.forEach((legendItem) => {
      const { location } = legendItem.dataset;
      legendItem.classList.toggle("is-active", location === idStr);
    });

    if (this.variableColumSizes) {
      this.scrollToVariableColumn(idStr); 
    } else {
      this.scrollToFixedColumn(idStr);
    }

    this.pins.forEach((pin) => {
      const className = `location-${idStr}`;
      pin.classList.toggle("is-active", pin.classList.contains(className));
    });
  }

  scrollToVariableColumn(idStr: string) { 
    DEBUG_VERBOSE && console.log('[Dollhouse] Scrolling to variable location');

    let count:number = 0;
    let total:number = 0;
    let sizes:number[] = this.columnSizes as Array<number>;  

    while (total < parseInt(idStr)) {  
      total += sizes[count];
      count++;  
    }

    this.swiper.slideTo(count - 1);

  }

  scrollToFixedColumn(idStr: string) {  
    DEBUG_VERBOSE && console.log('[Dollhouse] Scrolling to constant location');
    // Scroll the legend to the right place
    let slide = Math.floor(
      (parseInt(idStr) - 1) / parseInt(this.dataset.legendRows)
    );
    this.swiper.slideTo(slide);
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initLegendItems() {
    this.legendItems.forEach((legendItem) => {
      legendItem.addEventListener("click", () => {
        const { location: idStr } = legendItem.dataset;
        this.setActivePin(idStr);
      });
    });
  }

  initSwiper() {
    const swiperEl = this.querySelector(".swiper") as HTMLElement;

    DEBUG_VERBOSE && console.log('[Dollhouse] ' + this.dataset.legendRows);

    /* Check if we have a constant or variable number of columns */ 
    if (this.dataset.legendRows.indexOf(',') !== -1) {
      this.columnSizes = this.dataset.legendRows.split(',').map(Number);  
    } else {
      this.columnSizes = parseInt(this.dataset.legendRows);
    }

    /* Store a flag we can reference later */
    this.variableColumSizes = typeof this.columnSizes === 'object';

    DEBUG_VERBOSE && console.log('[Dollhouse] ' + this.columnSizes);
    DEBUG_VERBOSE && console.log('[Dollhouse] ' + typeof this.columnSizes);

    this.swiper = new Swiper(swiperEl, this.swiperOptions);

    if (window.innerWidth <= 720) {
      document
        .querySelector(".chunkwc-dollhouse-v1")
        .addEventListener("click", (e) => {
          // @ts-ignore
          if (e.target.tagName == "svg") return;

          // @ts-ignore
          let g = e.target.closest("g"),
            text = g.querySelector("text");

          if (window.innerWidth <= 375) {
            if (text.innerHTML <= 5) {
              this.swiper.slideTo(0, 300, true);
            } else if (text.innerHTML <= 10) {
              this.swiper.slideTo(1, 300, true);
            } else if (text.innerHTML <= 15) {
              this.swiper.slideTo(2, 300, true);
            } else {
              this.swiper.slideTo(3, 300, true);
            }
          } else {
            if (text.innerHTML < 11) {
              this.swiper.slideTo(1, 300, true);
            } else {
              this.swiper.slideTo(2, 300, true);
            }
          }
        });
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.destroy();
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}
}

customElements.define(TAG_NAME, DollhouseBlockV1);