import './ShortcutMenu.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = 'ChunkwcShortcutMenu';
const TAG_NAME = 'chunkwc-shortcut-menu';
const TOLERANCE = 210;

interface AnchorReference {
	hash: string;
	start: number;
	end: number;
	linkEl: HTMLElement;
	targetEl: HTMLElement;
}
// ////////////////////////////////////////////////////////////////////

export default class ShortcutMenuBlock extends HTMLElement {
	private header: HTMLElement;
	private hero: HTMLElement;
	private target: HTMLElement;
	private shortcutMenu: HTMLElement;
	private shortcutVisible: boolean = false;
	private triggerPoint: number;
	private quickShow: boolean;
	private anchors: HTMLLinkElement[];
	private anchorRefs: AnchorReference[] = [];
	private lastScrollPosition: number = 0;

	private parallax: boolean = false;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// anchors - remove anchors when apge has no anchor sections and add margin to hero blocks
		// and set anchors to use scroll rather than jump

		this.shortcutMenu = this.querySelector('.anchors');
		this.shortcutVisible = false;

		this.parallax = document.body.classList.contains('parallax');
		this.quickShow = this.dataset.quickshow == '1';

		//if (this.shortcutMenu != null) {

		this.header = document.querySelector('chunkwc-header') as HTMLElement;
		this.hero = document.querySelector('chunkwc-hero');

		// this.target = this.quickShow ? this.header : this.hero || this.header;
		this.target = this.header;

		this.triggerPoint =
			this.target.getBoundingClientRect().top + document.documentElement.scrollTop + this.target.getBoundingClientRect().height;

		if (this.parallax) {
			this.target = this.hero.nextElementSibling as HTMLElement;

			this.triggerPoint = this.target.getBoundingClientRect().top + document.documentElement.scrollTop;
		}

		this.anchors = Array.from(this.querySelectorAll<HTMLLinkElement>('.anchors__list a'));

		this.createMapForAnchorHighlights();

		for (let anchor of this.anchors) {
			// Override links on the shortcut menu so that we can use the smoothscroll
			// but also make sure that they allow for the menu height when scrolling
			// to their respective anchors.

			// And disregard all of this if the link is to a new page

			anchor.addEventListener('click', (event: any) => {
				if (event.target.hash) {
					let linkURL = event.target.href.split('#').shift();
					let currentURL = document.location.href.split('#').shift();

					if (linkURL == currentURL) {
						event.preventDefault();

						if (currentURL == linkURL) {
							let selectedAnchor = document.querySelector(`${event.target.hash}`);
							let shortcutMenuHeight = document.querySelector('chunkwc-shortcut-menu')
								? document.querySelector('chunkwc-shortcut-menu').clientHeight
								: 0;
							if (selectedAnchor) {
								window.scrollTo({
									left: 0,
									top: selectedAnchor.getBoundingClientRect().top + (document.documentElement.scrollTop - shortcutMenuHeight),
									behavior: 'smooth',
								});
							}
						}
					}
				}
			});
		}
		// }

		this.addScrollListener();
		//}

		document.querySelector('.anchors__top').addEventListener('click', (e) => {
			e.preventDefault();

			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth',
			});
		})
	}

	createMapForAnchorHighlights() {
		if (this.anchors.length) {
			this.anchors.forEach((anchor: any) => {
				if (anchor.hash) {
					let el = document.querySelector(anchor.hash);
					if (el) {
						let prevEl = this.anchorRefs[this.anchorRefs.length - 1];
						if (prevEl) {
							prevEl.end = el.offsetTop;
						}
						let ref = {
							hash: anchor.hash,
							start: el.offsetTop,
							end: 0,
							linkEl: anchor,
							targetEl: el,
						};

						// @ts-ignore
						this.anchorRefs.push(ref);
					}
				}
			});

			if(this.anchorRefs[this.anchorRefs.length - 1] !== undefined) {
				this.anchorRefs[this.anchorRefs.length - 1].end = document.documentElement.offsetHeight;
			}
		}
	}

	addScrollListener() {
		document.addEventListener('scroll', () => {
			if(window.scrollY <= 50) {
				this.shortcutMenu.classList.toggle('visible', false);
			}
			
			// Only run this if we are on desktop
			if (window.innerWidth >= 1366) {
				
				let elementToHighlight: any;

				if (this.lastScrollPosition < document.documentElement.scrollTop) {
					/* Scrolling down the page */
					elementToHighlight = this.getLowestVisibleItem();
				} else {
					/* Scrolling up the page */
					elementToHighlight = this.getHighestVisibleItem();
				}

				if (elementToHighlight) {
					DEBUG_VERBOSE && console.log(elementToHighlight.hash + ' is the element to highlight');
					this.anchorRefs.forEach((anchor) => {
						anchor.linkEl.classList.toggle('highlight', false);
					});

					elementToHighlight.linkEl.classList.toggle('highlight', true);
				} else {
					this.anchorRefs.forEach((anchor) => {
						anchor.linkEl.classList.toggle('highlight', false);
					});
				}

				if (this.target) {
					// this.triggerPoint =
					// 	this.target.getBoundingClientRect().top + document.documentElement.scrollTop + this.target.getBoundingClientRect().height;

					let scrollPosition = window.scrollY;

					if (scrollPosition > (this.shortcutMenu.classList.contains('anchors--parallax') ? 117 : this.triggerPoint)) {
						if (!this.shortcutVisible) {
							this.shortcutMenu.classList.toggle('visible', true);
							this.shortcutVisible = true;
						}
					} else {
						this.shortcutMenu.classList.toggle('visible', false);
						this.shortcutVisible = false;
					}
				}

				this.lastScrollPosition = document.documentElement.scrollTop;
			}
		});
	}

	getLowestVisibleItem() {
		// Find the lowest item in view when scrolling down the page */
		let lowestVisibleEl: any = null;
		this.anchorRefs
			.slice()
			.reverse()
			.every((anchor) => {
				// if (anchor.start <= window.scrollY + 117) {
				if (anchor.start <= window.scrollY + TOLERANCE) {
					lowestVisibleEl = anchor;
					DEBUG_VERBOSE && console.log(anchor.hash + ' is in view');
					return false;
				} else return true;
			});

		return lowestVisibleEl;
	}

	getHighestVisibleItem() {
		// Find the highest item in view when scrolling up the page */

		let highestVisibleEl: any = null;
		this.anchorRefs.slice().every((anchor) => {
			if (anchor.end - window.scrollY - TOLERANCE >= 0) {
				highestVisibleEl = anchor;
				DEBUG_VERBOSE && console.log(anchor.hash + ' is in view');
				return false;
			} else return true;
		});

		if (highestVisibleEl) {
			if (highestVisibleEl.targetEl.getBoundingClientRect().top < TOLERANCE) {
				return highestVisibleEl;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, ShortcutMenuBlock);