import BasicSlotElement from "../BasicSlotElement";
import "./ImageSlider.scss";

import Swiper, { Navigation, EffectFade } from "swiper";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "swiper/css/effect-fade";
import MathUtils from "../../utils/Math";

const BAR_START_POSITION_PERCENTAGE: number = 50;
const PERSIST_BAR_POSITION: boolean = true;

const ATTRACTOR_LOOP_TIME: number = 4;
const TWEEN_DURATION_SECONDS: number = 1;
const TWEEN_EASING: string = "power2.inOut";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[ImageSliderBlock]";
const TAG_NAME = "chunkwc-image-slider";

// ////////////////////////////////////////////////////////////////////

export default class ImageSliderBlock extends BasicSlotElement {
  private sliderWrapperEl: HTMLElement;
  private afterImageEl: HTMLElement;
  private beforeImageEl: HTMLElement;
  private arrowEl: HTMLElement;
  private swiperEl: HTMLElement;

  private captionBar: HTMLElement;
  private prevCaption: HTMLElement;
  private nextCaption: HTMLElement;
  private beforeTitle: HTMLElement;
  private afterTitle: HTMLElement;
  private caption: HTMLElement;
  private startPosition: number;

  private attractorPlaying: boolean = false;
  private userHasInteracted: boolean = false;
  private resizeTimeout: any;

  private touching: boolean = false;

  private swiperInstance!: Swiper;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.swiperInstance.destroy(true, true);
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  // ////////////////////////////////////////////////////////////////////

  init() {
    this.getDOMReferences();

    this.setupScrollTrigger();

    this.startPosition =
      parseInt(this.dataset.startposition) || BAR_START_POSITION_PERCENTAGE;

    this.setBarPosition(
      this.sliderWrapperEl.clientWidth * (this.startPosition / 100),
      0
    );

    this.createSwiper();
  }

  getDOMReferences() {
    this.sliderWrapperEl = this.querySelector(".slider-wrapper") as HTMLElement;
    this.afterImageEl = this.querySelector(".image.after") as HTMLElement;
    this.beforeImageEl = this.querySelector(".image.before") as HTMLElement;
    this.arrowEl = this.beforeImageEl.querySelector(
      ".ba-arrow-wrapper"
    ) as HTMLElement;

    this.captionBar = this.querySelector(".ba-captions") as HTMLElement;
    this.prevCaption = this.querySelector(".previous_slide") as HTMLElement;
    this.nextCaption = this.querySelector(".next_slide") as HTMLElement;
    this.caption = this.querySelector(".current_slide") as HTMLElement;

    this.beforeTitle = this.querySelector(".ba-title.before") as HTMLElement;
    this.afterTitle = this.querySelector(".ba-title.after") as HTMLElement;
  }

  setupScrollTrigger() {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: this,
      onToggle: (self) => {
        if (self.isActive) {
          this.startAttractor();
        } else {
          this.stopAttractor();
        }
      },
    });
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	BAR / ARROW
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  setBarPosition(x: number, duration: number = TWEEN_DURATION_SECONDS) {
    const clickPositionAsPercentage: number =
      (x / this.sliderWrapperEl.clientWidth) * 100;
    gsap.killTweensOf(this.afterImageEl);
    gsap.to(this.afterImageEl, {
      duration: duration,
      width: `${clickPositionAsPercentage}%`,
      ease: TWEEN_EASING,
    });
    this.setArrowPosition(x, duration);
  }

  setArrowPosition(x: number, duration: number = TWEEN_DURATION_SECONDS) {
    gsap.killTweensOf(this.arrowEl);
    gsap.to(this.arrowEl, {
      x: `${x}px`,
      duration: duration,
      ease: TWEEN_EASING,
    });

    this.updateTitleOpacity(x);
  }

  getBarPositionForCurrentSlide() {
    return this.getBarPositionForSlide(
      this.swiperInstance.slides[this.swiperInstance.activeIndex]
    );
  }

  getBarPositionForSlide(slide: any) {
    const image: HTMLElement = slide.querySelector(
      ".image.after"
    ) as HTMLElement;
    return image.getBoundingClientRect().width;
  }

  prepareNextSlideBarPosition(
    slide: Element,
    position: number = this.startPosition
  ) {
    if (slide) {
      this.setBarPosition(position, 0);
    }
  }

  updateTitleOpacity(x: number) {
    if (this.beforeTitle && this.afterTitle) {
      const fadeDist = 30;
      const margin = 10;

      const leftX = this.beforeTitle.offsetLeft + this.beforeTitle.offsetWidth;
      const rightX = this.afterTitle.offsetLeft;

      if (x <= leftX + margin + fadeDist) {
        const min = leftX + margin;

        const max = leftX + margin + fadeDist;

        const clampX = MathUtils.Clamp(x, min, max);

        const opacity = MathUtils.MapLinear(clampX, min, max, 0, 1);

        this.beforeTitle.style.opacity = `${opacity}`;
      } else {
        this.beforeTitle.style.opacity = "1";
      }

      if (x >= rightX - margin - fadeDist) {
        const min = rightX - margin - fadeDist;

        const max = rightX - margin;

        const clampX = MathUtils.Clamp(x, min, max);

        const opacity = MathUtils.MapLinear(clampX, min, max, 1, 0);

        this.afterTitle.style.opacity = `${opacity}`;
      } else {
        this.afterTitle.style.opacity = "1";
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    if (this.arrowEl && this.swiperEl) {
      const leftX = 10;
      const rightX = this.swiperEl.offsetWidth - 10;

      const fadeDistance = 20;
      const margin = 0;

      if (x <= leftX + margin + fadeDistance) {
        const min = leftX + margin;

        const max = leftX + margin + fadeDistance;

        const clampX = MathUtils.Clamp(x, min, max);

        const opacity = MathUtils.MapLinear(clampX, min, max, 0, 1);

        this.arrowEl.style.opacity = `${opacity}`;
      } else if (x >= rightX - margin - fadeDistance) {
        const min = rightX - margin - fadeDistance;

        const max = rightX - margin;

        const clampX = MathUtils.Clamp(x, min, max);

        const opacity = MathUtils.MapLinear(clampX, min, max, 1, 0);

        this.arrowEl.style.opacity = `${opacity}`;
      } else {
        this.arrowEl.style.opacity = "1";
      }
    }
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	ATTRACTOR
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  startAttractor(delay: number = ATTRACTOR_LOOP_TIME, repeat: boolean = true) {
    if (!this.swiperInstance || this.attractorPlaying || this.userHasInteracted)
      return;

    this.attractorPlaying = true;

    const DURATION: number = 1;
    const EASE: string = "back.inOut(4)";
    const BOUNCE_PERCENTAGE: number = 5;

    this.stopAnimation();

    const startx = this.getBarPositionForCurrentSlide();
    const bouncex =
      startx + this.sliderWrapperEl.clientWidth * (BOUNCE_PERCENTAGE / 100);

    const tl = gsap.timeline({
      repeat: repeat ? -1 : 0,
      onComplete: () => {
        this.attractorPlaying = false;
      },
    });

    tl.to(this.afterImageEl, {
      delay: delay,
      duration: DURATION,
      width: `${this.startPosition + BOUNCE_PERCENTAGE}%`,
      ease: EASE,
    });
    tl.to(
      this.arrowEl,
      { x: `${bouncex}px`, duration: DURATION, ease: EASE },
      "<"
    );
    tl.to(this.afterImageEl, {
      duration: DURATION,
      width: `${this.startPosition}%`,
      ease: EASE,
    });
    tl.to(
      this.arrowEl,
      { x: `${startx}px`, duration: DURATION, ease: EASE },
      "<"
    );
  }

  stopAttractor() {
    this.stopAnimation();
    this.attractorPlaying = false;
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	ANIMATION METHODS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  stopAnimation() {
    gsap.killTweensOf(this.afterImageEl);
    gsap.killTweensOf(this.arrowEl);
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	SWIPER
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  createSwiper() {
    this.swiperInstance = new Swiper(
      this.querySelector(".swiper") as HTMLElement,
      {
        loop: false,
        modules: [Navigation, EffectFade],
        speed: 2000,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          prevEl: this.querySelector(".previous_slide") as HTMLElement,
          nextEl: this.querySelector(".next_slide") as HTMLElement,
        },
        on: {
          init: (swiper) => {
            this.swiperEl = this.querySelector(".swiper") as HTMLElement;
            this.updateCaptions(swiper);
            this.addListeners(swiper.slides[swiper.activeIndex]);
          },
          slideChangeTransitionStart: (swiper) => {
            const previousSlide = swiper.slides[swiper.previousIndex];
            const activeSlide = swiper.slides[swiper.activeIndex];

            this.updateCaptions(swiper);

            if (PERSIST_BAR_POSITION) {
              this.prepareNextSlideBarPosition(
                activeSlide,
                this.getBarPositionForSlide(previousSlide)
              );
            } else {
              this.prepareNextSlideBarPosition(activeSlide);
            }
          },
          slideChange: (swiper) => {
            this.addListeners(swiper.slides[swiper.activeIndex]);
          },
        },
      }
    );

    this.swiperInstance.allowTouchMove = false;
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	CAPTIONS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  updateCaptions(swiper: Swiper) {
    const previousSlide = swiper.activeIndex
      ? swiper.slides[swiper.activeIndex - 1]
      : null;
    const activeSlide = swiper.slides[swiper.activeIndex];
    const nextSlide = !swiper.isEnd
      ? swiper.slides[swiper.activeIndex + 1]
      : null;

    const TWEEN_DURATION: number = 0.5;

    if (swiper.slides) {
      gsap.to(this.captionBar, {
        duration: TWEEN_DURATION,
        opacity: 0,
        onComplete: () => {
          this.prevCaption.lastElementChild.innerHTML = previousSlide
            ? `${previousSlide.getAttribute("data-title")}`
            : ``;
          this.prevCaption.style.visibility = previousSlide
            ? "visible"
            : "hidden";
          this.caption.innerHTML = activeSlide.getAttribute("data-title");
          if (this.nextCaption) {
            this.nextCaption.firstElementChild.innerHTML = nextSlide
            ? `${nextSlide.getAttribute("data-title")}`
            : ``;
            this.nextCaption.style.visibility = nextSlide ? "visible" : "hidden";
          }
        },
      });
      gsap.to(this.captionBar, { delay: TWEEN_DURATION, opacity: 1 });
    }
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	LISTENERS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  addListeners(currentSlide: Element) {
    window.addEventListener("resize", this.onResize.bind(this));

    // Add desktop listeners
    this.swiperEl.addEventListener("mouseenter", this.onMouseEnter.bind(this));
    this.swiperEl.addEventListener("pointerdown", this.onMouseDown.bind(this));

    this.swiperEl.addEventListener("mouseleave", this.onMouseLeave.bind(this));

    this.swiperEl.addEventListener("pointerup", this.onMouseUp.bind(this));
    this.swiperEl.addEventListener("mousemove", this.onMouseMove.bind(this));

    // Add touch listeners
    this.swiperEl.addEventListener("touchstart", this.onTouchStart.bind(this), {
      passive: true,
    });
    this.swiperEl.addEventListener("touchend", this.onTouchEnd.bind(this), {
      passive: true,
    });
    this.swiperEl.addEventListener("touchmove", this.onTouchMove.bind(this), {
      passive: true,
    });

    if (currentSlide) {
      this.afterImageEl = currentSlide.querySelector(".image.after");
      this.beforeImageEl = currentSlide.querySelector(".image.before");
      this.arrowEl = this.beforeImageEl.querySelector(".ba-arrow-wrapper");
    }
  }

  onResize() {
    this.stopAnimation();

    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.setArrowPosition(this.getBarPositionForCurrentSlide(), 0);
      });
    }, 300);
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	MOUSE LISTENERS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  onMouseEnter() {}

  onMouseLeave() {
    this.touching = false;
  }

  onMouseDown(event: any) {
    this.stopAnimation();
    this.touching = true;
    this.userHasInteracted = true;
    const sliderBounds = this.getBoundingClientRect();
    const clickXPos = event.clientX - sliderBounds.x;
    this.setBarPosition(clickXPos);
  }

  onMouseUp() {
    this.touching = false;
  }

  onMouseMove(event: any) {
    if (this.touching) {
      const sliderBounds = this.getBoundingClientRect();
      this.setBarPosition(event.clientX - sliderBounds.x, 0);
    }
  }

  /**
	------------------------------------------------------------------------------------------------------------------------------------------
	TOUCH LISTENERS
	------------------------------------------------------------------------------------------------------------------------------------------
	*/

  onTouchStart() {
    this.stopAnimation();
    this.touching = true;
    this.userHasInteracted = true;
  }

  onTouchEnd(event: any) {
    this.touching = false;
    const touch = event.changedTouches[0];
    const sliderBounds = this.getBoundingClientRect();
    this.setBarPosition(touch.clientX - sliderBounds.x);
  }

  onTouchMove(event: any) {
    const touch = event.changedTouches[0];
    const sliderBounds = this.getBoundingClientRect();
    this.setBarPosition(touch.clientX - sliderBounds.x, 0);
  }
}

customElements.define(TAG_NAME, ImageSliderBlock);
