export default class MathUtils {
  // Calculates the greatest common divisor of two integers.
  static GlobalCommonDivisor(a: number, b: number): number {
    return b === 0 ? a : MathUtils.GlobalCommonDivisor(b, a % b);
  }

  // Calculates a random number that is biased towards a certain value.
  // influence should value between 0.0 and 1.0
  static GetRndBias(
    min: number,
    max: number,
    bias: number,
    influence: number
  ): number {
    const rnd = Math.random() * (max - min) + min; // random in range
    const mix = Math.random() * influence; // random mixer
    return rnd * (1 - mix) + bias * mix; // mix full range and bias
  }

  static Norm(value: number, min: number, max: number) {
    return (value - min) / (max - min);
  }

  static Lerp(norm: number, min: number, max: number): number {
    return (max - min) * norm + min;
  }

  static MapLinear(
    value: number,
    sourceMin: number,
    sourceMax: number,
    destMin: number,
    destMax: number
  ): number {
    return this.Lerp(this.Norm(value, sourceMin, sourceMax), destMin, destMax);
  }

  static Clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  static DistanceBetween(
    p0: { x: number; y: number },
    p1: { x: number; y: number }
  ): number {
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;

    return Math.sqrt(dx * dx + dy * dy);
  }

  static AngleBetweenPointsInRad(
    p0: { y: number; x: number },
    p1: { y: number; x: number }
  ): number {
    return Math.atan2(p1.y - p0.y, p1.x - p0.x);
  }

  static RadToDeg(rad: number): number {
    return (rad * 180) / Math.PI;
  }

  static DegToRad(deg: number): number {
    return (deg * Math.PI) / 180;
  }

  static RandomRange(min: number, max: number): number {
    return min + Math.random() * (max - min);
  }

  static RandomInt(min: number, max: number): number {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  static AngleDiff(angle1: number, angle2: number): number {
    const diff = ((angle2 - angle1 + 180) % 360) - 180;
    return diff < -180 ? diff + 360 : diff;
  }
}
